<template>
  <div class="flex flex-col justify-center items-center absolute inset-0">
    <form class="auth-container relative">
      <div class="title mx-auto text-center">¿Olvidaste tu contraseña?</div>

      <p class="mt-2">Te enviaremos un correo con una liga para que la restablezcas.</p>

      <div class="flex flex-col w-full mt-6">
        <custom-label for="email" value="Correo electrónico"></custom-label>
        <custom-input
          id="email"
          type="email"
          v-model="form.email"
        ></custom-input>
        <validation-errors :errors="errorFor('email')"></validation-errors>
      </div>

      <primary-button
        class="w-full mt-4"
        :loading="loading"
        @buttonClicked="sendResetLink"
        >Enviar Link
      </primary-button>

      <div class="mt-6 text-center">
        <router-link :to="{ name: 'login' }" class="link">Regresar</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import api from "../../api";
import GoogleButton from "../../components/ui/GoogleButton";
import validationErrors from "../../mixins/validationErrors";
import { is422 } from "../../utils/response";
import { mapState } from "vuex";
import { useHead } from '@unhead/vue'
export default {
  name: "Forgot Password",

  setup() {
    useHead({
      titleTemplate: '%s | Recuperar Contraseña'
    })
  },

  components: {
    GoogleButton,
  },

  data() {
    return {
      form: {
        email: null,
      },
      loading: false,
    };
  },

  mixins: [validationErrors],

  computed: {
    ...mapState({}),
  },

  methods: {
    validateForm() {
      this.errors = {};

      this.validateEmail(this.form.email);

      return Object.keys(this.errors).length === 0;
    },

    async sendResetLink() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      try {
        const response = await api.auth.sendResetLink(this.form);
        this.$store.dispatch("successSnack", response.message);
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
    },
  },
};
</script>

