export default {
  path: '/invitation-plans',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async getAll() {
    try {
      const response = await this.apiClient.get(this.path);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getUpgradeOptions(form) {
    try {
      const response = await this.apiClient.post(this.path + '/upgrade-options', form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
}