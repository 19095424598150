let animatedScrollObserver;

const scrollAnimationDirective = {
  beforeMount(el, binding) {
    el.classList.add('before-enter');
    let animationClass = binding.value || 'enter';

    animatedScrollObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(animationClass);
            animatedScrollObserver.unobserve(entry.target);
          }
        });
      },
      {
        threshold: .2, // This will trigger the callback when 10% of the item is visible
      }
    );

    animatedScrollObserver.observe(el);
  },
  unmounted(el) {
    animatedScrollObserver.unobserve(el);
  }
}

export default scrollAnimationDirective;
