<template>
  <div class="flex flex-col justify-center items-center absolute inset-0">
    <form class="auth-container mx-auto mt-6">
      <p class="title mx-auto">Crea tu Boda</p>

      <div class="flex flex-col w-full mt-6">
        <custom-label
          for="name"
          value="Nombre"
          details="(Nombre que se mostrará en la portada de tu invitación. Ej. Sofía & Roberto)"
        ></custom-label>
        <custom-input
          class="mr-4"
          id="name"
          type="text"
          v-model="form.name"
        ></custom-input>
        <validation-errors :errors="errorFor('name')"></validation-errors>
      </div>

      <div class="flex flex-col w-full mt-2">
        <custom-label
          for="slug"
          value="Identificador"
          details="(Es la ruta que identfificará tu invitación, sólo minúsculas, números y '-' disponibles. Ej. sofiayroberto)"
        ></custom-label>
        <custom-input
          class="mr-4"
          id="slug"
          type="text"
          v-model="form.slug"
          @input="restrictInput"
        ></custom-input>
        <validation-errors :errors="errorFor('slug')"></validation-errors>
      </div>

      <div class="flex flex-col w-full mt-2">
        <custom-label for="date" value="Fecha"></custom-label>
        <input
          class="mr-4"
          type="datetime-local"
          id="date"
          v-model="form.date"
        />
        <validation-errors :errors="errorFor('date')"></validation-errors>
      </div>

      <primary-button class="w-full mt-12" :loading="loading" @buttonClicked="create"
        >Crear Boda</primary-button
      >
    </form>

    <button
      class="button-interactive absolute w-full bottom-4 left-0 right-0 sm:w-fit sm:top-4 sm:bottom-auto sm:left-auto sm:right-4 font-semibold z-50"
      @click="$store.dispatch('logout')"
    >
      Cerrar Sesión
    </button>
  </div>
</template>

<script>
import api from "../../api";
import validationErrors from "../../mixins/validationErrors";
import { is422 } from "../../utils/response";
import moment from "moment-timezone";
import { mapState } from "vuex";
export default {
  name: "Wedding Form",

  data() {
    return {
      form: {
        name: null,
        slug: null,
        date: null,
      },
      loading: false,
    };
  },

  computed: {
    ...mapState({
      client: "client",
    }),
  },

  mixins: [validationErrors],

  created() {
    // if (
    //   this.client &&
    //   this.client.weddings &&
    //   this.client.weddings.length > 0
    // ) {
    //   this.$router.push({
    //     name: "wedding",
    //     params: { id: this.client.weddings[0].id },
    //   });
    // }
  },

  methods: {
    async create() {
      this.loading = true;
      let originalDate = this.form.date;

      try {
        let localDateTime = moment.tz(
          this.form.date,
          "YYYY-MM-DDTHH:mm",
          "America/Mexico_City"
        );

        this.form.date = localDateTime.tz("UTC").format();

        const response = await api.weddings.create(this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$store.commit("createClientWedding", response.wedding);
        this.$router.push({
          name: "wedding",
          params: { id: response.wedding.id },
        });
      } catch (error) {
        this.form.date = originalDate;
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    restrictInput(event) {
      let regex = /[^a-z0-9-]/g;

      if (regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(regex, "");
        this.form.slug = event.target.value;
      }
    },
  },
};
</script>