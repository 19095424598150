export default {
  path: '/parents',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async create(form) {
    try {
      const response = await this.apiClient.post(`${this.path}`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateName(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/name`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateShowIfPastAway(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/show-if-past-away`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async delete(id) {
    try {
      const response = await this.apiClient.delete(`${this.path}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
}