<template>
  <div v-if="!confirmation" class="flex flex-col text-xs">
    <div :class="[{ 'text-success-500': validateLength }]">
      Al menos 8 caracteres
    </div>
    <div :class="[{ 'text-success-500': validateUpperCase }]">
      Al menos 1 letra mayúscula
    </div>
    <div :class="[{ 'text-success-500': validateLowerCase }]">
      Al menos 1 letra minúscula
    </div>
    <div :class="[{ 'text-success-500': validateNumber }]">
      Al menos 1 número
    </div>
    <div :class="[{ 'text-success-500': validateSpecialChar }]">
      Al menos 1 caracter especial (.@$!%*?&/)
    </div>
  </div>

  <div v-else class="flex flex-col text-xs">
    <div :class="[{ 'text-success-500': validatePasswordConfirmation }]">
      Misma contraseña
    </div>
  </div>
</template>

<script>
export default {
  props: {
    password: String,
    passwordConfirmation: String,
    confirmation: Boolean,
  },

  computed: {
    validateLength() {
      return this.password.length > 7;
    },

    validateUpperCase() {
      return /[A-Z]/.test(this.password);
    },

    validateLowerCase() {
      return /[a-z]/.test(this.password);
    },

    validateNumber() {
      return /\d/.test(this.password);
    },

    validateSpecialChar() {
      return /[.@$!%*?&/]/.test(this.password);
    },

    validatePasswordConfirmation() {
      return (
        this.password === this.passwordConfirmation && this.password !== ""
      );
    },
  },
};
</script>