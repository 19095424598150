<template>
  <div class="flex items-center bg-gray-50 absolute inset-0">
    <div
      class="flex flex-col w-120 h-96 p-8 shadow bg-white rounded-xl mx-auto"
    >
      <div v-if="loading" class="flex w-full h-full items-center">
        <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
      </div>

      <div v-else class="flex items-center w-full h-full">
        <div v-if="verifyError" class="w-full content text-center">
          {{ verifyError }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api";
export default {
  name: "Client Verification",

  data() {
    return {
      form: {
        token: "",
      },
      loading: false,
      verifyError: null,
    };
  },

  async created() {
    this.verifyClient();
  },

  methods: {
    async verifyClient() {
      this.form.token = this.$route.params.token;

      this.loading = true;

      try {
        const response = await api.auth.verifyClient(this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$store.dispatch("login", response);
        this.$router.push({ name: "wedding_form" });
      } catch (error) {
        this.verifyError = error.response.data.message;
      }

      this.loading = false;
    },
  },
};
</script>