<template>
  <div class="flex flex-col justify-center items-center absolute inset-0">
    <form class="auth-container">
      <div class="title">Completa tus datos</div>

      <div class="flex flex-col w-full mt-6">
        <custom-label for="name" value="Nombre(s)"></custom-label>
        <custom-input id="name" type="text" v-model="form.name"></custom-input>
        <validation-errors :errors="errorFor('name')"></validation-errors>
      </div>

      <div class="flex flex-col w-full mt-6">
        <custom-label for="lastName" value="Apellido(s)"></custom-label>
        <custom-input
          id="lastName"
          type="text"
          v-model="form.lastName"
        ></custom-input>
        <validation-errors :errors="errorFor('lastName')"></validation-errors>
      </div>

      <primary-button
        class="w-full mt-4"
        :loading="loading"
        @buttonClicked="register"
        >Enviar
      </primary-button>
    </form>

    <button
      class="button-interactive absolute w-full bottom-4 left-0 right-0 sm:w-fit sm:top-4 sm:bottom-auto sm:left-auto sm:right-4 font-semibold z-50"
      @click="$store.dispatch('logout')"
    >
      Cerrar Sesión
    </button>
  </div>
</template>

<script>
import api from "../../api";
import validationErrors from "../../mixins/validationErrors";
import { is422 } from "../../utils/response";
import { mapState } from "vuex";
export default {
  name: "Complete Client",

  data() {
    return {
      form: {
        name: "",
        lastName: "",
      },
      loading: false,
    };
  },

  mixins: [validationErrors],

  computed: {
    ...mapState({
      client: "client",
    }),
  },

  methods: {
    validateForm() {
      this.errors = {};

      this.validateName(this.form.name);
      this.validateLastName(this.form.lastName);

      return Object.keys(this.errors).length === 0;
    },

    async register() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      try {
        const response = await api.clients.updateNameAndLastName(
          this.client.id,
          this.form
        );
        this.$store.commit("setClient", response.client);
        this.$store.dispatch("successSnack", response.message);
        this.$router.push({ name: "wedding_form" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
    },
  },
};
</script>