export function isLoggedIn() {
  return localStorage.getItem("authToken") != null;
}

export function logIn(token) {
  localStorage.setItem("authToken", token);
}

export function logOut() {
  localStorage.removeItem("authToken");
}