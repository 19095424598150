<template>
  <button
    class="fill-current button-interactive stroke-current text-content-500"
    @click="buttonClicked"
  >
    <copy-icon></copy-icon>
  </button>
</template>

<script>
import CopyIcon from "../../icons/CopyIcon";
export default {
  components: {
    CopyIcon,
  },

  props: {
    loading: Boolean,
  },

  methods: {
    buttonClicked() {
      this.$emit("buttonClicked");
    },
  },
};
</script>