<template>
  <label>
    <span v-if="value">{{ value }}</span>
    <span v-if="required" class="text-red-500">*</span>
    <span class="block text-xs font-medium text-content-400" v-if="details">{{ details }}</span>
  </label>
</template>

<script>
export default {
  props: ["value", "required", "details"],
};
</script>

