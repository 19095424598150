<template>
  <div
    class="flex flex-col justify-center items-center fixed z-50 inset-0 bg-opacity-25 bg-black"
  >
    <form class="modal relative">
      <h1 class="mx-auto">Pago</h1>

      <p class="mt-6 text-left font-semibold text-lg text-content-400">
        Total:
        <span class="font-bold text-secondary-500">{{
          integerToCurrency(plan.amount)
        }}</span>
      </p>

      <p class="text-left font-semibold text-lg text-content-400">
        Plan: <span class="font-bold text-secondary-500">{{ plan.name }}</span>
      </p>

      <div class="flex flex-col mt-6">
        <custom-label
          class="text-left"
          for="cardholderName"
          value="Nombre del Tarjetahabiente"
        ></custom-label>

        <custom-input
          class="w-full"
          id="cardholderName"
          type="text"
          v-model="cardholderName"
        ></custom-input>

        <validation-errors
          :errors="errorFor('cardholderName')"
        ></validation-errors>
      </div>

      <div class="flex flex-col mt-4">
        <custom-label
          class="text-left"
          for="card-element"
          value="Datos de la Tarjeta"
        ></custom-label>

        <div id="card-element"></div>

        <validation-errors :errors="errorFor('card')"></validation-errors>

        <div class="flex mx-auto mt-1 items-center">
          <safe-icon class="stroke-current text-content-600 w-6" />
          <p class="text-xs text-content-400">
            Tu pago está protegido por Stripe, líder en seguridad de pagos en
            línea.
          </p>
        </div>

        <stripe-icon class="mx-auto w-12" />
      </div>

      <div class="flex items-center mt-10">
        <input
          id="checkbox"
          type="checkbox"
          class="h-5 w-5"
          v-model="acceptsTerms"
        />
        <span for="checkbox" class="ml-2"
          >He leído y acepto los Términos y Condiciones</span
        >
      </div>
      <validation-errors :errors="errorFor('acceptsTerms')"></validation-errors>

      <a
        class="mt-2 mx-auto link underline text-xs"
        :href="getTermsUrl()"
        target="_blank"
        >Aviso de Privacidad y Términos y Condiciones</a
      >

      <primary-button
        class="w-full mt-10"
        :loading="loading"
        @click.prevent="checkout"
        >Pagar
      </primary-button>

      <button v-if="!loading" class="link mt-4 mx-auto w-full" @click.prevent="close">
        Cancelar
      </button>
    </form>
  </div>
</template>

<script>
import api from "../../api";
import SafeIcon from "../../icons/SafeIcon";
import StripeIcon from "../../icons/StripeIcon";
import validationErrors from "../../mixins/validationErrors";
import { loadStripe } from "@stripe/stripe-js";
import { mapState } from "vuex";
export default {
  name: "Checkout Modal",

  components: {
    SafeIcon,
    StripeIcon,
  },

  data() {
    return {
      stripe: null,
      card: null,
      loading: false,
      cardholderName: null,
      acceptsTerms: false,
    };
  },

  props: {
    plan: Object,
    currentProductId: Number
  },

  computed: {
    ...mapState({
      wedding: "wedding",
    }),
  },

  mixins: [validationErrors],

  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

    const elements = this.stripe.elements({
      fonts: [
        {
          cssSrc:
            "https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;400;600;700&display=swap",
        },
      ],
      locale: "es-419",
    });

    this.card = elements.create("card", {
      classes: {
        base: "bg-white border py-3 px-3 rounded-lg outline-none smoot font-sans",
      },
      style: {
        base: {
          fontFamily: "Be Vietnam Pro",
        },
      },
    });

    this.card.mount("#card-element");
  },

  methods: {
    close() {
      this.$emit("close");
    },

    validateForm() {
      this.errors = {};

      if (!this.cardholderName) {
        this.errors["cardholderName"] = ["Campo requerido."];
      }

      if (!this.acceptsTerms) {
        this.errors["acceptsTerms"] = [
          "Debes aceptar los Términos y Condiciones.",
        ];
      }

      return Object.keys(this.errors).length === 0;
    },

    async checkout(e) {
      e.preventDefault();

      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;
      this.paymentError = null;

      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.card,
        billing_details: {
          name: this.cardholderName,
        },
      });

      if (error) {
        this.errors["card"] = [error.message];
        this.loading = false;
      } else {
        try {
          const response = await api.payments.checkout({
            invitationPlanId: this.plan.id,
            paymentMethodId: paymentMethod.id,
            priceId: this.plan.priceId,
            weddingId: this.wedding.id,
            currentProductId: this.currentProductId
          });

          this.$store.dispatch("successSnack", response.message);
          this.$emit("loadWedding");
          this.$emit("loadUpgradeOptions");
          this.close();
        } catch (error) {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }

        this.loading = false;
      }
    },

    integerToCurrency(amount) {
      const formatter = new Intl.NumberFormat({
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
      });

      return "$" + formatter.format(amount) + "MXN";
    },

    getTermsUrl() {
      const route = this.$router.resolve({ name: 'terms' });
      return route.href;
    }
  },
};
</script>

<style scoped>
input[type="checkbox"] {
  accent-color: #316cf6;
}
</style>