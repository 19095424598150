<template>
  <div class="flex items-center">
    <div class="flex flex-col w-full">
      <custom-label
        :for="field"
        :value="title"
        :details="details"
      ></custom-label>

      <div class="flex items-center">
        <input
          class="mr-4"
          type="datetime-local"
          :id="field"
          v-model="form[field]"
          :disabled="!edit"
        />

        <button
          v-if="!edit && active"
          class="button-interactive"
          @click.prevent="edit = true"
        >
          <edit-icon class="w-4" />
        </button>

        <div v-else-if="edit" class="flex">
          <button
            class="button-interactive text-error-500 mr-4"
            @click.prevent="
              edit = false;
              setInitialDate();
            "
          >
            <close-icon class="w-4 fill-current" />
          </button>

          <button
            class="button-interactive text-success-500"
            @click.prevent="updateField()"
          >
            <check-icon class="w-8 fill-current" />
          </button>
        </div>
      </div>

      <validation-errors :errors="errorFor(field)"></validation-errors>
    </div>
  </div>
</template>

<script>
import api from "../../api";
import validationErrors from "../../mixins/validationErrors";
import { is422 } from "../../utils/response";
import moment from "moment-timezone";

export default {
  name: "WC Datetime",

  data() {
    return {
      form: {},
      edit: false,
      loading: false,
    };
  },

  props: {
    field: String,
    value: String,
    title: String,
    details: String,
    id: Number,
    resource: Object,
    active: Boolean,
  },

  mixins: [validationErrors],

  created() {
    this.setInitialDate();
  },

  methods: {
    setInitialDate() {
      const dateWithTZ = moment.tz(this.value, "UTC");
      const formattedDate = dateWithTZ
        .clone()
        .tz("America/Mexico_City")
        .format("YYYY-MM-DDTHH:mm");

      this.form[this.field] = formattedDate;
    },

    async updateField() {
      this.loading = true;

      try {
        let localDateTime = moment.tz(
          this.form[this.field],
          "YYYY-MM-DDTHH:mm",
          "America/Mexico_City"
        );

        localDateTime = localDateTime.tz("UTC").format();

        const request = {};
        request[this.field] = localDateTime;

        const response = await api[this.resource.api][this.resource.name](
          this.id,
          request
        );

        this.$store.dispatch("successSnack", response.message);
        this.$emit("updateDate", this.form[this.field]);
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
        this.setInitialDate();
      }

      this.loading = false;
      this.edit = false;
    },
  },
};
</script>