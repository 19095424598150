<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 176 176">
    <g data-name="Layer 2">
      <g data-name="01.facebook">
        <circle cx="88" cy="88" r="88" fill="#3a559f" />
        <path
          fill="#fff"
          d="m115.88 77.58-1.77 15.33a2.87 2.87 0 0 1-2.82 2.57h-16l-.08 45.45a2.05 2.05 0 0 1-2 2.07H77a2 2 0 0 1-2-2.08V95.48H63a2.87 2.87 0 0 1-2.84-2.9l-.06-15.33a2.88 2.88 0 0 1 2.84-2.92H75v-14.8C75 42.35 85.2 33 100.16 33h12.26a2.88 2.88 0 0 1 2.85 2.92v12.9a2.88 2.88 0 0 1-2.85 2.92h-7.52c-8.13 0-9.71 4-9.71 9.78v12.81h17.87a2.88 2.88 0 0 1 2.82 3.25z"
        />
      </g>
    </g>
  </svg>
</template>