<template>
  <div class="flex flex-col p-8 bg-background-500">
    <section class="section">
      <div v-if="client" class="mt-6">
        <p>{{ client.name }} {{ client.lastName }}</p>
        <p>{{ client.email }}</p>
        <p v-if="client.externalAuth === 'google'">Autenticación con Google</p>
      </div>

      <div class="mt-6">
        <p class="font-bold text-lg">Soporte</p>
        <p>
          Envíanos Whatsapp al
          <a
            href="https://api.whatsapp.com/send?phone=528128728916"
            target="_blank"
            class="underline"
            >8128728916</a
          >
          ó un correo a contacto@daisi.mx.
        </p>
      </div>

      <button
        class="link underline mt-6 justify-left w-fit"
        @click.prevent="$store.dispatch('logout')"
      >
        Cerrar Sesión
      </button>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Account",

  computed: {
    ...mapState({
      client: "client",
    }),
  },

  created() {
    this.$store.commit("setSelectedTab", "account");
  },
};
</script>