<template>
  <div v-if="weddingToEdit" class="p-4 sm:p-8">
    <section class="section">
      <h2>Portada</h2>

      <w-c-string
        class="mt-4"
        field="name"
        :value="weddingToEdit.name"
        title="Nombre"
        details="(Nombre de la boda, se mostrará en la portada de tu invitación. Ej. Sofía & Roberto)"
        :id="weddingToEdit.id"
        model="wedding"
        :resource="{ api: 'weddings', name: 'updateName' }"
        :active="wedding.active"
        @updateName="weddingToEdit.name = $event"
      />

      <w-c-string
        class="mt-4"
        field="slug"
        :value="weddingToEdit.slug"
        title="Identificador"
        details="(Es la ruta que identfificará tu invitación, si requieres modificarlo, comunícate con nosotros)"
        :id="weddingToEdit.id"
        model="wedding"
        :resource="{ api: 'weddings', name: 'updateSlug' }"
        :regex="'[^a-z0-9-]'"
        :active="false"
        @updateSlug="weddingToEdit.slug = $event"
      />

      <w-c-datetime
        class="mt-4"
        field="date"
        :value="weddingToEdit.date"
        title="Fecha"
        details="(Si requieres modificar la fecha, comunícate con nosotros)"
        :id="weddingToEdit.id"
        :resource="{ api: 'weddings', name: 'updateDate' }"
        :active="false"
        @updateDate="weddingToEdit.date = $event"
      />

      <w-c-images
        class="mt-4"
        :images="weddingToEdit.coverImages"
        type="coverImage"
        message="Imagen de portada"
        :id="weddingToEdit.id"
        idField="weddingId"
        :max="1"
        :enabled="true"
        :active="wedding.active"
        @addImage="addImage($event)"
        @deleteImage="deleteImage($event, 'coverImages')"
      />

      <w-c-song
        class="mt-4"
        :song="weddingToEdit.song"
        :weddingId="weddingToEdit.id"
        :enabled="validateIfFeatureAvailable('song')"
        :active="wedding.active"
        @addSong="weddingToEdit.song = $event"
        @deleteSong="weddingToEdit.song = null"
        @updatePlayAutomatically="weddingToEdit.song.playAutomatically = $event"
      />
    </section>

    <section class="section mt-8">
      <h2>Invitación</h2>

      <w-c-text
        class="mt-4"
        field="firstMessage"
        :value="weddingToEdit.invitation.firstMessage"
        title="Primer mensaje"
        :id="weddingToEdit.invitation.id"
        :resource="{ api: 'invitations', name: 'updateFirstMessage' }"
        :active="wedding.active"
        @updateFirstMessage="weddingToEdit.invitation.firstMessage = $event"
      />

      <brides-parents
        class="mt-4"
        :invitation="weddingToEdit.invitation"
        :active="wedding.active"
        @createParent="createParent($event, 'bridesParents')"
        @updateName="updateParentName($event, 'bridesParents')"
        @updateShowIfPastAway="
          updateParentShowIfPastAway($event, 'bridesParents')
        "
        @deleteParent="deleteParent($event, 'bridesParents')"
      />

      <grooms-parents
        class="mt-4"
        :invitation="weddingToEdit.invitation"
        :active="wedding.active"
        @createParent="createParent($event, 'groomsParents')"
        @updateName="updateParentName($event, 'groomsParents')"
        @updateShowIfPastAway="
          updateParentShowIfPastAway($event, 'groomsParents')
        "
        @deleteParent="deleteParent($event, 'groomsParents')"
      />

      <w-c-text
        class="mt-4"
        field="secondMessage"
        :value="weddingToEdit.invitation.secondMessage"
        title="Segundo mensaje"
        :id="weddingToEdit.invitation.id"
        :resource="{ api: 'invitations', name: 'updateSecondMessage' }"
        @updateSecondMessage="weddingToEdit.invitation.secondMessage = $event"
      />
    </section>

    <section class="section mt-8">
      <h2>Eventos</h2>

      <div
        v-for="(event, index) in weddingToEdit.events"
        :key="event.id"
        class="flex flex-col w-full rounded-lg shadow-primary p-4 mb-4"
      >
        <w-c-string
          field="name"
          :value="event.name"
          title="Nombre"
          :id="event.id"
          model="events"
          :resource="{ api: 'events', name: 'updateName' }"
          :deleteEnabled="true"
          :active="wedding.active"
          @updateName="event.name = $event"
          @delete="
            weddingToEdit.events = weddingToEdit.events.filter(
              (e) => e.id != event.id
            )
          "
        />

        <div class="flex flex-col w-full rounded-lg shadow-primary p-4 mb-4">
          <w-c-string
            field="place"
            :value="event.place"
            title="Lugar"
            :id="event.id"
            model="events"
            :resource="{ api: 'events', name: 'updatePlace' }"
            :active="wedding.active"
            @updatePlace="event.place = $event"
          />

          <w-c-string
            field="address"
            :value="event.address"
            title="Dirección"
            :id="event.id"
            model="events"
            :resource="{ api: 'events', name: 'updateAddress' }"
            :active="wedding.active"
            @updateAddress="event.address = $event"
          />

          <w-c-string
            field="addressLink"
            :value="event.addressLink"
            title="Liga de mapa"
            :id="event.id"
            model="events"
            :resource="{ api: 'events', name: 'updateAddressLink' }"
            :active="wedding.active"
            @updateAddressLink="event.addressLink = $event"
          />

          <w-c-datetime
            field="datetime"
            :value="event.datetime"
            title="Fecha"
            :id="event.id"
            :resource="{ api: 'events', name: 'updateDatetime' }"
            :active="wedding.active"
            @updateDatetime="event.datetime = $event"
          />

          <w-c-flag
            field="showAddToCalendar"
            :value="event.showAddToCalendar"
            title="Habilitar Agregar a Calendario"
            :id="event.id"
            model="event"
            :resource="{ api: 'events', name: 'updateShowAddToCalendar' }"
            :active="wedding.active"
            @update="event.showAddToCalendar = $event"
          />

          <w-c-flag
            field="onlyAdults"
            :value="event.onlyAdults"
            title="Sólo adultos"
            :id="event.id"
            model="event"
            :resource="{ api: 'events', name: 'updateOnlyAdults' }"
            :active="wedding.active"
            @update="event.onlyAdults = $event"
          />

          <w-c-flag
            field="isExclusive"
            :value="event.isExclusive"
            title="Es exclusivo"
            details="(Sólo los invitados agregados podrán ver el evento en la invitación)"
            :id="event.id"
            model="event"
            :resource="{ api: 'events', name: 'updateIsExclusive' }"
            :enabled="validateIfFeatureAvailable('exclusiveEvents')"
            :active="wedding.active"
            @update="event.isExclusive = $event"
          />

          <exclusive-guests
            v-if="event.isExclusive"
            :exclusiveGuests="event.guests || []"
            :eventId="event.id"
            :weddingId="weddingToEdit.id"
            :active="wedding.active"
            @addGuest="addGuest(index, $event)"
            @removeGuest="
              event.guests = event.guests.filter((guest) => guest.id != $event)
            "
          />

          <w-c-images
            class="mt-4"
            :images="event.images || []"
            type="eventImage"
            message="Imágenes del lugar"
            :id="event.id"
            idField="eventId"
            :max="getFeatureMaxValue('EventImages')"
            :enabled="validateIfFeatureAvailable('EventImages')"
            :active="wedding.active"
            :showMax="true"
            @addImage="addChildImage('events', index, $event)"
            @deleteImage="deleteChildImage('events', index, $event)"
          />
        </div>
      </div>

      <secondary-button
        v-if="!addEvent && wedding.active"
        @buttonClicked="addEvent = true"
        >Agregar</secondary-button
      >

      <w-c-string
        v-else-if="addEvent"
        field="name"
        title="Nombre"
        model="event"
        :resource="{ api: 'events' }"
        :createEnabled="true"
        :createForm="{
          weddingId: weddingToEdit.id,
        }"
        :active="wedding.active"
        @create="
          weddingToEdit.events.push($event);
          addEvent = false;
        "
        @cancel="addEvent = false"
      />
    </section>

    <section class="section mt-8">
      <h2>Código de vestimenta</h2>

      <w-c-string
        class="mt-4"
        field="dressCode"
        :value="weddingToEdit.dressCode"
        title="Código de vestimenta"
        :id="weddingToEdit.id"
        model="wedding"
        :resource="{ api: 'weddings', name: 'updateDressCode' }"
        :active="wedding.active"
        @updateDressCode="weddingToEdit.dressCode = $event"
      />

      <w-c-flag
        field="showDressCodeIcons"
        :value="weddingToEdit.showDressCodeIcons"
        title="Mostrar íconos"
        :id="weddingToEdit.id"
        model="wedding"
        :resource="{ api: 'weddings', name: 'updateShowDressCodeIcons' }"
        :active="wedding.active"
        @update="weddingToEdit.showDressCodeIcons = $event"
      />
    </section>

    <section class="section mt-8">
      <h2>Mesa de regalos</h2>

      <div
        v-for="giftRegistry in weddingToEdit.giftRegistries"
        :key="giftRegistry.id"
        class="flex flex-col w-full rounded-lg shadow-primary p-4 mb-4"
      >
        <w-c-string
          field="name"
          :value="giftRegistry.name"
          title="Nombre"
          :id="giftRegistry.id"
          model="giftRegistries"
          :resource="{ api: 'giftRegistries', name: 'updateName' }"
          :deleteEnabled="true"
          :active="wedding.active"
          @updateName="giftRegistry.name = $event"
          @delete="
            weddingToEdit.giftRegistries = weddingToEdit.giftRegistries.filter(
              (r) => r.id != giftRegistry.id
            )
          "
        />

        <div class="flex flex-col w-full rounded-lg shadow-primary p-4 mb-4">
          <w-c-string
            field="link"
            :value="giftRegistry.link"
            title="Liga"
            :id="giftRegistry.id"
            model="giftRegistries"
            :resource="{ api: 'giftRegistries', name: 'updateLink' }"
            :active="wedding.active"
            @updateLink="giftRegistry.link = $event"
          />
        </div>
      </div>

      <secondary-button
        v-if="!addGiftRegistry && wedding.active"
        @buttonClicked="addGiftRegistry = true"
        >Agregar</secondary-button
      >

      <w-c-string
        v-else-if="addGiftRegistry"
        field="name"
        title="Nombre"
        model="giftRegistry"
        :resource="{ api: 'giftRegistries' }"
        :createEnabled="true"
        :createForm="{
          weddingId: weddingToEdit.id,
        }"
        :active="wedding.active"
        @create="
          weddingToEdit.giftRegistries.push($event);
          addGiftRegistry = false;
        "
        @cancel="addGiftRegistry = false"
      />

      <w-c-flag
        class="mt-4"
        field="acceptsEnvelope"
        :value="weddingToEdit.acceptsEnvelope"
        title="Acepta sobre de regalo"
        :id="weddingToEdit.id"
        model="wedding"
        :resource="{ api: 'weddings', name: 'updateAcceptsEnvelope' }"
        :active="wedding.active"
        @update="weddingToEdit.acceptsEnvelope = $event"
      />
    </section>

    <section class="section mt-8">
      <h2>Confirmación de invitados</h2>

      <w-c-flag
        class="mt-4"
        field="confirmationEnabled"
        :value="weddingToEdit.confirmationEnabled"
        title="Confirmación habilitada"
        :id="weddingToEdit.id"
        model="wedding"
        :resource="{ api: 'weddings', name: 'updateConfirmationEnabled' }"
        :active="wedding.active"
        @update="weddingToEdit.confirmationEnabled = $event"
      />

      <w-c-flag
        class="mt-4"
        field="foodRestrictionsEnabled"
        :value="weddingToEdit.foodRestrictionsEnabled"
        title="Restricción de alimentos habilitada"
        :id="weddingToEdit.id"
        model="wedding"
        :resource="{ api: 'weddings', name: 'updateFoodRestrictionsEnabled' }"
        :active="wedding.active"
        @update="weddingToEdit.foodRestrictionsEnabled = $event"
      />

      <w-c-flag
        class="mt-4"
        field="messageEnabled"
        :value="weddingToEdit.messageEnabled"
        title="Mensaje para novios habilitado"
        :id="weddingToEdit.id"
        model="wedding"
        :resource="{ api: 'weddings', name: 'updateMessageEnabled' }"
        :active="wedding.active"
        @update="weddingToEdit.messageEnabled = $event"
      />
    </section>

    <section class="section mt-8">
      <h2>Hospedaje</h2>

      <div
        v-for="(accommodation, index) in weddingToEdit.accommodations"
        :key="accommodation.id"
        class="flex flex-col w-full rounded-lg shadow-primary p-4 mb-4"
      >
        <w-c-string
          field="name"
          :value="accommodation.name"
          title="Nombre"
          :id="accommodation.id"
          model="events"
          :resource="{ api: 'accommodations', name: 'updateName' }"
          :deleteEnabled="true"
          :active="wedding.active"
          @updateName="accommodation.name = $event"
          @delete="
            weddingToEdit.accommodations = weddingToEdit.accommodations.filter(
              (e) => e.id != accommodation.id
            )
          "
        />

        <div class="flex flex-col w-full rounded-lg shadow-primary p-4 mb-4">
          <w-c-string
            field="link"
            :value="accommodation.link"
            title="Liga"
            :id="accommodation.id"
            model="accommodations"
            :resource="{ api: 'accommodations', name: 'updateLink' }"
            :active="wedding.active"
            @updatePlace="accommodation.place = $event"
          />

          <w-c-string
            field="address"
            :value="accommodation.address"
            title="Dirección"
            :id="accommodation.id"
            model="accommodations"
            :resource="{ api: 'accommodations', name: 'updateAddress' }"
            :active="wedding.active"
            @updateAddress="accommodation.address = $event"
          />

          <w-c-string
            field="addressLink"
            :value="accommodation.addressLink"
            title="Liga de mapa"
            :id="accommodation.id"
            model="accommodations"
            :resource="{ api: 'accommodations', name: 'updateAddressLink' }"
            :active="wedding.active"
            @updateAddressLink="accommodation.addressLink = $event"
          />

          <w-c-string
            field="message"
            :value="accommodation.message"
            title="Mensaje"
            :id="accommodation.id"
            model="accommodations"
            :resource="{ api: 'accommodations', name: 'updateMessage' }"
            :active="wedding.active"
            @updateMessage="accommodation.message = $event"
          />
        </div>
      </div>

      <secondary-button
        v-if="!addAccommodation && wedding.active"
        @buttonClicked="addAccommodation = true"
        >Agregar</secondary-button
      >

      <w-c-string
        v-else-if="addAccommodation"
        field="name"
        title="Nombre"
        model="accommodation"
        :resource="{ api: 'accommodations' }"
        :createEnabled="true"
        :createForm="{
          weddingId: weddingToEdit.id,
        }"
        :active="wedding.active"
        @create="
          weddingToEdit.accommodations.push($event);
          addAccommodation = false;
        "
        @cancel="addAccommodation = false"
      />
    </section>

    <section class="section mt-8">
      <h2>Galería</h2>

      <w-c-images
        class="mt-4"
        :images="weddingToEdit.galleryImages"
        type="galleryImage"
        message="Imágenes de galería"
        :id="weddingToEdit.id"
        idField="weddingId"
        :max="getFeatureMaxValue('GalleryImages')"
        :enabled="validateIfFeatureAvailable('GalleryImages')"
        :active="wedding.active"
        :showMax="true"
        @addImage="addImage($event)"
        @deleteImage="deleteImage($event, 'galleryImages')"
      />
    </section>
  </div>
  <div v-else class="flex w-full h-full items-center">
    <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
  </div>
</template>

<script>
import api from "../../../api";
import WCString from "../../../components/db-resources/String";
import WCDatetime from "../../../components/db-resources/Datetime";
import WCImages from "../../../components/db-resources/Images";
import WCSong from "../../../components/db-resources/Song";
import WCText from "../../../components/db-resources/Text";
import WCFlag from "../../../components/db-resources/Flag";
import BridesParents from "../../../components/sections/BridesParents";
import GroomsParents from "../../../components/sections/GroomsParents";
import ExclusiveGuests from "../../../components/sections/ExclusiveGuests";
import { mapState } from "vuex";
export default {
  name: "Editing",

  components: {
    WCString,
    WCDatetime,
    WCImages,
    WCSong,
    WCText,
    WCFlag,
    BridesParents,
    GroomsParents,
    ExclusiveGuests,
  },

  data() {
    return {
      weddingToEdit: null,
      loadingWedding: false,
      addEvent: false,
      addGiftRegistry: false,
      addAccommodation: false,
    };
  },

  computed: {
    ...mapState({
      wedding: "wedding",
      collapsed: "collapsed",
    }),
  },

  created() {
    this.$store.commit("setSelectedTab", "editing");
    this.weddingToEdit = JSON.parse(JSON.stringify(this.wedding));
  },

  methods: {
    addImage(image) {
      this.weddingToEdit[`${image.type}s`].push(image);
    },

    addChildImage(model, index, image) {
      if (!this.weddingToEdit[model][index].images) {
        this.weddingToEdit[model][index].images = [];
      }

      this.weddingToEdit[model][index].images.push(image);
    },

    deleteImage(id, type) {
      this.weddingToEdit[type] = this.weddingToEdit[type].filter(
        (image) => image.id !== id
      );
    },

    deleteChildImage(model, index, id) {
      this.weddingToEdit[model][index].images = this.weddingToEdit[model][
        index
      ].images.filter((image) => image.id !== id);
    },

    createParent(parent, type) {
      this.weddingToEdit.invitation[type].push(parent);
    },

    updateParentName(parent, type) {
      this.weddingToEdit.invitation[type].filter(
        (p) => parent.id === p.id
      )[0].name = parent.name;
    },

    updateParentShowIfPastAway(parent, type) {
      this.weddingToEdit.invitation[type].filter(
        (p) => parent.id === p.id
      )[0].showIfPastAway = parent.showIfPastAway;
    },

    deleteParent(id, type) {
      this.weddingToEdit.invitation[type] = this.weddingToEdit.invitation[
        type
      ].filter((parent) => parent.id != id);
    },

    addGuest(eventIndex, guest) {
      if (!this.weddingToEdit.events[eventIndex].guests) {
        this.weddingToEdit.events[eventIndex].guests = [];
      }

      this.weddingToEdit.events[eventIndex].guests.push(guest);
    },

    validateIfFeatureAvailable(feature) {
      if (!this.wedding.invitationPlan) {
        return true;
      }

      return this.wedding.invitationPlan.invitationPlanFeatures.some(
        (invitationPlanFeature) => {
          return invitationPlanFeature.slug
            .toLowerCase()
            .includes(feature.toLowerCase());
        }
      );
    },

    getFeatureMaxValue(feature) {
      if (!this.wedding.invitationPlan) {
        return 1;
      }

      const foundFeature =
        this.wedding.invitationPlan.invitationPlanFeatures.find(
          (invitationPlanFeature) => {
            return invitationPlanFeature.slug
              .toLowerCase()
              .includes(feature.toLowerCase());
          }
        );

      return foundFeature ? foundFeature.max : null;
    },
  },

  watch: {
    weddingToEdit: function (val) {
      this.$store.commit("setWedding", val);
    },
  },
};
</script>