<template>
  <div class="flex items-center">
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteImage"
      ></delete-modal>
    </transition>
    <div class="flex flex-col w-full">
      <custom-label
        for="image"
        :value="message + (showMax && active && max ? ' (máximo ' + max + ')' : '')"
      ></custom-label>

      <div v-if="enabled" class="flex flex-col w-full">
        <div class="flex flex-wrap w-full">
          <div
            v-for="image in images"
            :key="image.id"
            :style="{
              width: 200 / image.aspectRatio + 'px',
              height: '200px',
            }"
            class="flex-shrink-0 rounded-lg inset-0 object-cover overflow-hidden mr-4 mb-2 relative"
            :class="[
              { 'animate-pulse': loadingDelete && image.id === imageToDelete },
            ]"
          >
            <img
              class="w-full h-full"
              v-lazy="{
                src: image.src,
                loading: require('@/assets/bg_placeholder.jpg'),
              }"
            />
            <button
              v-if="!loadingDelete"
              class="w-8 h-8 fill-current button-interactive absolute text-error-500 bg-white right-2 top-2 p-2 rounded-full shadow-primary"
              @click.prevent="
                showDeleteModal = true;
                imageToDelete = image.id;
              "
            >
              <trashcan-icon />
            </button>
          </div>
        </div>

        <div class="flex items-center">
          <div v-if="!imagePreview" class="w-full">
            <input
              class="hidden"
              type="file"
              name="image"
              :id="'image_' + type + '_' + id"
              ref="image"
              accept="image/*"
              @change="getImage"
            />

            <label
              v-if="images.length < max && active"
              :for="'image_' + type + '_' + id"
              class="cursor-pointer secondary-btn"
              :class="[{ 'mt-2': images.length === 0 }]"
              >Agregar</label
            >
          </div>

          <div v-else class="flex items-center">
            <img
              ref="imagePreview"
              class="rounded-lg object-cover object-center mr-4"
              :class="[{ 'animate-pulse': loadingCreating }]"
              :style="{
                height: '200px',
              }"
              :src="imagePreview"
            />
            <div v-if="!loadingCreating" class="flex">
              <button
                class="button-interactive text-error-500 mr-4"
                @click.prevent="imagePreview = null"
              >
                <close-icon class="w-4 fill-current" />
              </button>

              <button
                class="button-interactive text-success-500"
                @click.prevent="storeImage()"
              >
                <check-icon class="w-8 fill-current" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <p v-else class="text-error-500 font-semibold text-sm">No incluído en tu paquete.</p>
    </div>
  </div>
</template>

<script>
import api from "../../api";
import deleteMixin from "../../mixins/deleteMixin";
export default {
  name: "WC Images",

  data() {
    return {
      loading: false,
      imageToDelete: null,
      imagePreview: null,
      newImage: null,
      loadingCreating: false,
      loadingDelete: false,
    };
  },

  props: {
    images: Array,
    type: String,
    message: String,
    id: Number,
    idField: String,
    max: {
      type: Number,
      default: 0,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    active: Boolean,
    showMax: Boolean
  },

  mixins: [deleteMixin],

  methods: {
    async storeImage() {
      this.loadingCreating = true;

      try {
        const formData = new FormData();

        formData.append("type", this.type);
        formData.append(this.idField, this.id);
        formData.append("image", this.newImage);

        const response = await api.images.create(formData);

        this.imagePreview = null;
        this.$store.dispatch("successSnack", response.message);
        this.$emit("addImage", response.image);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingCreating = false;
    },

    async deleteImage() {
      this.showDeleteModal = false;
      this.loadingDelete = true;

      try {
        const response = await api.images.delete(this.imageToDelete);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("deleteImage", this.imageToDelete);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingDelete = false;
    },

    getImage(e) {
      let image = e.target.files[0];
      if (image) {
        let url = URL.createObjectURL(image);
        let img = new Image();

        img.onload = () => {
          URL.revokeObjectURL(url);
          let reader = new FileReader();
          reader.onload = (e) => {
            this.newImage = image;
            this.imagePreview = e.target.result;
          };
          reader.readAsDataURL(image);
        };

        img.onerror = () => {
          URL.revokeObjectURL(url);
          alert("Unable to load image");
        };

        img.src = url;
      }
    },
  },
};
</script>