<template>
  <button
    :disabled="loading"
    type="submit"
    @click.prevent="buttonClicked"
    class="primary-btn"
  >
    <img class="w-6 h-6 mr-2" :src="require('@/assets/google.png')" alt="" />
    <slot> </slot>
  </button>
</template>


<script>
export default {
  props: {
    loading: Boolean,
  },

  methods: {
    buttonClicked() {
      this.$emit("buttonClicked");
    },
  },
};
</script>