import ValidationErrors from "../components/ui/ValidationErrors"
export default {
    components: {
        ValidationErrors
    },
    data() {
        return {
            errors: {}
        }
    },
    methods: {
        errorFor(field, parent) {
            if (this.errors[field]) {
                return this.errors[field];
            }

            const fieldMatch = field.match(`^${parent}\[(\d+)\]\.(.+)$`);
            if (fieldMatch) {
                const index = fieldMatch[1];
                const f = fieldMatch[2];
                const errorField = `${parent}[${index}].${f}`;

                if (this.errors[errorField]) {
                    return this.errors[errorField];
                }
            }

            return null;
        },

        validateName(name) {
            if (!name) {
                this.errors['name'] = ['Campo requerido.'];
                return false;
            }

            return true;
        },

        validateLastName(lastName) {
            if (!lastName) {
                this.errors['lastName'] = ['Campo requerido.'];
                return false;
            }

            return true;
        },

        validateEmail(email) {
            if (!email) {
                this.errors['email'] = ['Campo requerido.'];
                return false;
            } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
                this.errors['email'] = ['Correo electrónico inválido.'];
                return false;
            }

            return true;
        },

        validateLoginPassword(password) {
            if (!password) {
                this.errors['password'] = ['Campo requerido.'];
                return false;
            }
        },

        validateRegisterPassword(password) {
            if (!password) {
                this.errors['password'] = ['Campo requerido.'];
                return false;
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(password)) {
                this.errors['password'] = ['Contraseña inválida.'];
            }

            return true;
        },

        validateCurrentPassword(password) {
            if (!password) {
                this.errors['current_password'] = ['Campo requerido.'];
                return false;
            }

            return true;
        },

        validateNewPassword(password) {
            if (!password) {
                this.errors['new_password'] = ['Campo requerido.'];
                return false;
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(password)) {
                this.errors['new_password'] = ['Contraseña inválida.'];
            }

            return true;
        },

        validatePasswordConfirmation(password, newPassword) {
            if (!newPassword) {
                this.errors['passwordConfirmation'] = ['Campo requerido.'];
                return false;
            } else if (password !== newPassword) {
                this.errors['passwordConfirmation'] = ['La contraseña no coincide.'];
                return false;
            }

            return true;
        },
    }
};