import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import VueLazyLoad from 'vue3-lazyload';
import { createHead } from '@vueuse/head'

// Buttons
import PrimaryButton from "./components/ui/PrimaryButton";
import SecondaryButton from "./components/ui/SecondaryButton";
import CloseButton from "./components/ui/CloseButton";
import CustomLabel from "./components/ui/CustomLabel";
import CustomInput from "./components/ui/CustomInput";
import CustomToggle from "./components/ui/CustomToggle";

// Icons
import DaisiIcon from "./icons/DaisiIcon";
import LogoIcon from "./icons/LogoIcon";
import LoaderIcon from "./icons/LoaderIcon";
import CheckIcon from "./icons/CheckIcon";
import CloseIcon from "./icons/CloseIcon";
import EditIcon from "./icons/EditIcon";
import TrashcanIcon from "./icons/TrashcanIcon";
import BackIcon from "./icons/BackIcon";
import CollapseIcon from "./icons/CollapseIcon";


// Directives
import Closable from "./directives/closable";
import ScrollAnimation from "./directives/scrollanimation";

const app = createApp(App);
const head = createHead();

app.component('primary-button', PrimaryButton);
app.component('secondary-button', SecondaryButton);
app.component('close-button', CloseButton);
app.component('custom-label', CustomLabel);
app.component('custom-input', CustomInput);
app.component('custom-toggle', CustomToggle);

app.component('daisi-icon', DaisiIcon);
app.component('logo-icon', LogoIcon);
app.component('loader-icon', LoaderIcon);
app.component('check-icon', CheckIcon);
app.component('close-icon', CloseIcon);
app.component('edit-icon', EditIcon);
app.component('trashcan-icon', TrashcanIcon);
app.component('back-icon', BackIcon);
app.component('collapse-icon', CollapseIcon);

app.directive('closable', Closable);
app.directive('scrollanimation', ScrollAnimation);

app.use(store).use(VueLazyLoad).use(head);

(async () => {
  await store.dispatch("loadClient");

  app.use(router);

  app.mount('#app');
})();

