export default {
  path: '/weddings',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async getAll() {
    try {
      const response = await this.apiClient.get(this.path);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getById(id) {
    try {
      const response = await this.apiClient.get(`${this.path}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async startTrial(id) {
    try {
      const response = await this.apiClient.get(`${this.path}/${id}/start-trial`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async create(form) {
    try {
      const response = await this.apiClient.post(`${this.path}`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateName(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/name`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateSlug(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/slug`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateDate(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/date`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateDressCode(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/dress-code`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateShowDressCodeIcons(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/show-dress-code-icons`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateAcceptsEnvelope(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/accepts-envelope`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateConfirmationEnabled(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/confirmation-enabled`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateFoodRestrictionsEnabled(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/food-restrictions-enabled`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateMessageEnabled(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/message-enabled`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

}