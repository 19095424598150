<template>
  <div class="flex items-center">
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteSong"
      ></delete-modal>
    </transition>

    <audio v-if="song || songPreview" ref="audioPlayer" loop>
      <source :src="song ? song.src : songPreview" type="audio/mpeg" />
    </audio>

    <div class="flex flex-col w-full">
      <custom-label for="song" :value="'Canción'"></custom-label>

      <div v-if="enabled" class="flex flex-col full">
        <div
          v-if="song"
          class="flex items-center"
          :class="[{ 'animate-pulse': loadingDelete }]"
        >
          <p class="mr-4">{{ song.name }}</p>
          <button
            class="bg-white text-content-500 rounded-full p-3 fill-current button-interactive shadow-primary mr-4"
            @click="toggleAudio"
          >
            <play-icon v-if="!audioPlaying" class="w-4 h-4" />
            <pause-icon v-else class="w-4 h-4" />
          </button>
          <button
            v-if="!loadingDelete"
            class="w-6 fill-current button-interactive text-error-500"
            @click.prevent="showDeleteModal = true"
          >
            <trashcan-icon />
          </button>
        </div>

        <div v-else class="flex items-center">
          <div v-if="!songPreview" class="w-full">
            <input
              class="hidden"
              type="file"
              name="song"
              id="song"
              ref="song"
              accept="audio/mpeg"
              @change="getSong"
            />

            <label v-if="active" for="song" class="cursor-pointer secondary-btn"
              >Agregar</label
            >
          </div>

          <div
            v-else
            class="flex items-center"
            :class="[{ 'animate-pulse': loadingCreating }]"
          >
            <p class="mr-4">{{ newSong.name }}</p>

            <button
              class="bg-white text-content-500 rounded-full p-3 fill-current button-interactive shadow-primary mr-4"
              @click="toggleAudio"
            >
              <play-icon v-if="!audioPlaying" class="w-4 h-4" />
              <pause-icon v-else class="w-4 h-4" />
            </button>
            <div v-if="!loadingCreating" class="flex">
              <button
                class="button-interactive text-error-500 mr-4"
                @click.prevent="
                  songPreview = null;
                  audioPlaying = false;
                "
              >
                <close-icon class="w-4 fill-current" />
              </button>

              <button
                class="button-interactive text-success-500"
                @click.prevent="storeSong()"
              >
                <check-icon class="w-8 fill-current" />
              </button>
            </div>
          </div>
        </div>

        <!-- <w-c-flag
          v-if="song"
          class="block"
          field="playAutomatically"
          :value="song.playAutomatically"
          title="Reproducir automáticamente"
          :id="song.id"
          model="song"
          :resource="{ api: 'songs', name: 'updatePlayAutomatically' }"
          :active="active"
          @update="$emit('updatePlayAutomatically', $event)"
        /> -->
      </div>

      <p v-else class="text-error-500 font-semibold text-sm">
        No incluído en tu paquete.
      </p>
    </div>
  </div>
</template>

<script>
import api from "../../api";
import PlayIcon from "../../icons/PlayIcon";
import PauseIcon from "../../icons/PauseIcon";
import deleteMixin from "../../mixins/deleteMixin";
import WCFlag from "./Flag";
export default {
  name: "Wedding Song",

  components: {
    PlayIcon,
    PauseIcon,
    WCFlag,
  },

  data() {
    return {
      loading: false,
      songPreview: null,
      newSong: null,
      loadingCreating: false,
      loadingDelete: false,
      audioPlaying: false,
    };
  },

  props: {
    song: Object,
    weddingId: Number,
    enabled: Boolean,
    active: Boolean,
  },

  mixins: [deleteMixin],

  methods: {
    async storeSong() {
      this.loadingCreating = true;

      try {
        const formData = new FormData();

        formData.append("weddingId", this.weddingId);
        formData.append("song", this.newSong);

        const response = await api.songs.create(formData);

        this.songPreview = null;
        this.$store.dispatch("successSnack", response.message);
        this.$emit("addSong", response.song);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingCreating = false;
    },

    async deleteSong() {
      this.showDeleteModal = false;
      this.loadingDelete = true;

      try {
        const response = await api.songs.delete(this.song.id);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("deleteSong", this.songToDelete);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingDelete = false;
    },

    getSong(e) {
      let song = e.target.files[0];
      if (song) {
        let url = URL.createObjectURL(song);

        this.newSong = song;
        this.songPreview = url;
      }
    },

    toggleAudio() {
      const audioPlayer = this.$refs.audioPlayer;

      if (this.audioPlaying) {
        audioPlayer.pause();
      } else {
        audioPlayer.play();
      }

      this.audioPlaying = !this.audioPlaying;
    },
  },
};
</script>