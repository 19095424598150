<template>
  <div class="flex flex-col">
    <custom-label
      :for="model + '_' + field"
      :value="title"
      :details="details"
    ></custom-label>

    <custom-toggle
      v-if="enabled"
      v-model="form[field]"
      :disabled="!active"
      @change="updateValue()"
    ></custom-toggle>

    <p v-else class="text-error-500 font-semibold text-sm">
      No incluído en tu paquete.
    </p>
  </div>
</template>

<script>
import api from "../../api";
import { is422 } from "../../utils/response";
export default {
  name: "WC Flag",

  data() {
    return {
      form: {},
      loading: false,
    };
  },

  props: {
    field: String,
    value: Boolean,
    title: String,
    details: String,
    id: Number,
    model: String,
    resource: Object,
    enabled: {
      type: Boolean,
      default: true,
    },
    active: Boolean,
  },

  created() {
    this.form[this.field] = this.value;
  },

  methods: {
    async updateValue() {
      this.loading = true;

      try {
        const response = await api[this.resource.api][this.resource.name](
          this.id,
          this.form
        );
        this.$store.dispatch("successSnack", response.message);
        this.$emit("update", this.form[this.field]);
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
    },
  },
};
</script>