<template>
  <button
    :disabled="loading"
    type="submit"
    @click.prevent="buttonClicked"
    class="secondary-btn"
  >
    <loader-icon v-if="this.loading" class="h-5 w-5"></loader-icon>
    <slot v-else></slot>
  </button>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },

  methods: {
    buttonClicked() {
      this.$emit("buttonClicked");
    },
  },
};
</script>