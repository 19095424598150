<template>
  <div class="p-8 flex flex-col">
    <div class="flex items-center">
      <router-link
        :to="{ name: 'guests' }"
        class="text-content-500 fill-current button-interactive mr-2"
      >
        <back-icon class="w-5"></back-icon>
      </router-link>
      <!-- <h2>Cargar Archivo</h2> -->
    </div>

    <section class="section mt-8">
      <p>
        ¡Puedes cargar un archivo CSV con todos tus invitados! Asegúrate que
        cada invitado contenga todos los campos necesarios y estén delimitados
        por ','. Descarga el archivo de prueba para ver el formato requerido.
      </p>

      <button class="link mt-6 underline w-fit">
        <a class="" href="/wc_ejemplo.csv" download
          >Descargar archivo de ejemplo</a
        >
      </button>

      <div class="mt-6">
        <custom-label for="file" value="Archivo CSV"></custom-label>
        <div v-if="!newFile" class="">
          <input
            class="hidden"
            type="file"
            name="csv"
            id="file"
            ref="csv"
            accept=".csv"
            @change="getCSV"
          />

          <label for="file" class="cursor-pointer secondary-btn">Agregar</label>
        </div>
        <div
          v-else
          class="flex items-center"
          :class="[{ 'animate-pulse': loadingCreating }]"
        >
          <p class="mr-4">{{ newFile.name }}</p>
          <div v-if="!loadingCreating" class="flex">
            <button
              class="button-interactive text-error-500 mr-4"
              @click.prevent="newFile = null"
            >
              <close-icon class="w-4 fill-current" />
            </button>

            <button
              class="button-interactive text-success-500"
              @click.prevent="uploadFile()"
            >
              <check-icon class="w-8 fill-current" />
            </button>
          </div>
        </div>
      </div>

      <p class="text-sm mt-2">
        * Los invitados del archivo no reemplazarán a los existentes, sino que
        se sumarán.
      </p>

      <div class="text-sm mt-4">
        <p v-if="totalRows">
          Total de registros: <span>{{ totalRows }}</span>
        </p>
        <p v-if="successfulRows">
          Registros con éxito: <span>{{ successfulRows }}</span>
        </p>
        <div v-if="errorRows">
          <p>Registros con error:</p>
          <div v-for="(row, index) in errorRows" :key="index">
            <p>
              Fila: <span>{{ row.index }}</span>
            </p>
            <p>
              Datos: <span>{{ row.row }}</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "../../../api";
import { mapState } from "vuex";
export default {
  name: "Guest Batch",

  data() {
    return {
      newFile: null,
      loadingCreating: false,
      totalRows: null,
      successfulRows: null,
      errorRows: null,
    };
  },

  computed: {
    ...mapState({
      wedding: "wedding",
    }),
  },

  created() {
    if (!this.validateIfFeatureAvailable("rsvpAdmin")) {
      this.$router.push({ name: "guests" });
    }
  },

  methods: {
    async uploadFile() {
      this.loadingCreating = true;

      this.totalRows = null;
      this.successfulRows = null;
      this.errorRows = null;

      const form = new FormData();
      form.append("weddingId", this.$route.params.id);
      form.append("csvFile", this.newFile);

      try {
        const response = await api.guests.uploadFile(form);
        this.$store.dispatch("successSnack", response.message);
        this.totalRows = response.totalRows;
        this.successfulRows = response.successfulRows;
        this.errorRows = response.errorRows;
        this.newFile = null;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingCreating = false;
    },

    getCSV(e) {
      let file = e.target.files[0];
      if (file) {
        this.newFile = file;
      }
    },

    validateIfFeatureAvailable(feature) {
      if (!this.wedding.invitationPlan) {
        return false;
      }

      return this.wedding.invitationPlan.invitationPlanFeatures.some(
        (invitationPlanFeature) => {
          return invitationPlanFeature.slug
            .toLowerCase()
            .includes(feature.toLowerCase());
        }
      );
    },
  },
};
</script>