export default {
  path: '/auth',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async getGoogleAuthRedirect() {
    try {
      const response = await this.apiClient.get(this.path + "/get-google-auth-redirect");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async login(form) {
    try {
      const response = await this.apiClient.post(this.path + "/login", form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async adminLogin(form) {
    try {
      const response = await this.apiClient.post(this.path + "/admin-login", form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async googleVerification(form) {
    try {
      const response = await this.apiClient.post(this.path + "/google-verification", form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async register(form) {
    try {
      const response = await this.apiClient.post(this.path + "/register", form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async verifyClient(form) {
    try {
      const response = await this.apiClient.post(this.path + "/verify-client", form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async resendVerification() {
    try {
      const response = await this.apiClient.post(this.path + "/resend-verification");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async sendResetLink(form) {
    try {
      const response = await this.apiClient.post(this.path + "/send-reset-link", form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async resetPassword(form) {
    try {
      const response = await this.apiClient.post(this.path + "/reset-password", form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
}