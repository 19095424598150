<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 422.139 422.139"
  >
    <path
      d="M363.631 174.498h-1.045v-25.6C362.586 66.664 295.923 0 213.688 0S64.79 66.664 64.79 148.898v25.6h-6.269c-22.988 0-40.751 20.375-40.751 43.886v65.306c-.579 22.787 17.425 41.729 40.212 42.308.18.005.359.008.539.01h38.661c5.476-.257 9.707-4.906 9.449-10.382a9.695 9.695 0 0 0-.045-.59v-128c0-6.269-3.657-12.539-9.404-12.539H85.688v-25.6c0-70.692 57.308-128 128-128s128 57.308 128 128v25.6h-11.494c-5.747 0-9.404 6.269-9.404 12.539v128c-.583 5.451 3.363 10.343 8.814 10.926.196.021.393.036.59.045h12.016l-1.045 1.567a82.545 82.545 0 0 1-66.351 32.914c-5.708-27.989-33.026-46.052-61.015-40.343-23.935 4.881-41.192 25.843-41.385 50.27.286 28.65 23.594 51.724 52.245 51.722a53.812 53.812 0 0 0 37.616-16.196 45.978 45.978 0 0 0 12.539-25.078 103.443 103.443 0 0 0 83.069-41.273l9.927-14.629c22.465-1.567 36.571-15.673 36.571-36.049v-65.306c.001-22.463-16.717-49.108-40.75-49.108zM85.688 305.11H58.521c-11.25-.274-20.148-9.615-19.874-20.865.005-.185.012-.37.021-.556v-65.306c0-12.016 8.359-22.988 19.853-22.988h27.167V305.11zm161.437 86.204a30.826 30.826 0 0 1-22.465 9.927c-16.998-.27-30.792-13.834-31.347-30.825-.007-17.024 13.788-30.83 30.812-30.837 17.024-.007 30.83 13.788 30.837 30.812v.025a27.692 27.692 0 0 1-7.837 20.898zm136.359-102.4c0 14.106-13.584 16.196-19.853 16.196h-21.943V195.396h21.943c11.494 0 19.853 16.196 19.853 28.212v65.306z"
    />
  </svg>
</template>