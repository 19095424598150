export default {
  path: '/accommodations',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async create(form) {
    try {
      const response = await this.apiClient.post(`${this.path}`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateName(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/name`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateLink(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/link`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateAddress(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/address`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateAddressLink(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/address-link`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateMessage(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/message`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async delete(id) {
    try {
      const response = await this.apiClient.delete(`${this.path}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
}