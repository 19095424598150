<template>
  <router-view class="" />
</template>

<script>
import AccountIcon from "../../icons/AccountIcon";
import { mapState } from "vuex";
import { useHead } from '@unhead/vue'
export default {
  name: "Dashboard",

  setup() {
    useHead({
      titleTemplate: '%s | CMS'
    })
  },

  components: {
    AccountIcon,
  },

  computed: {
    ...mapState({
      client: "client",
    }),
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>