<template>
  <div class="fixed bottom-4 right-4 md:right-8">
    <!-- <transition name="instagram">
      <a
        href="https://www.instagram.com/bluekit.mx/"
        target="_blank"
        v-show="showOptions"
        class="
          w-12 md:w-16
          h-12 md:h-16
          button-interactive
          shadow-primary
          rounded-full
          items-center
          flex
        "
      >
        <img class="w-full h-full mx-auto" :src="require('@/assets/instagram.png')" alt="Instagram link" title="instagram" />
      </a>
    </transition>

    <transition name="facebook">
      <a
        href="https://www.facebook.com/bluekit.mexico"
        target="_blank"
        v-show="showOptions"
        class="
          w-12 md:w-16
          h-12 md:h-16
          button-interactive
          bg-white
          shadow-primary
          rounded-full
          items-center
          flex
          mt-4
        "
      >
        <facebook-icon class="w-full h-full mx-auto" />
      </a>
    </transition> -->

    <transition name="whatsapp">
      <a
        href="https://api.whatsapp.com/send?phone=528128728916"
        target="_blank"
        v-show="showOptions"
        class="
          w-12 md:w-16
          h-12 md:h-16
          button-interactive
          bg-white
          shadow-primary
          rounded-full
          items-center
          flex
          mt-4
        "
      >
        <whatsapp-icon class="w-full h-full mx-auto" />
      </a>
    </transition>

    <!-- <button
      class="
        w-12 md:w-16
        h-12 md:h-16
        button-interactive
        bg-white
        shadow-primary
        rounded-full
        items-center
        flex
        mt-4
      "
      @click="showOptions = !showOptions"
    >
      <contact-icon v-if="!showOptions" class="w-6 md:w-10 h-6 md:h-10 mx-auto" />
      <close-icon v-else class="w-4 md:w-6 h-4 md:h-6 mx-auto" />
    </button> -->
  </div>
</template>

<script>
import WhatsappIcon from "../../icons/WhatsappIcon";
import FacebookIcon from "../../icons/FacebookIcon";
import ContactIcon from "../../icons/ContactIcon";
import CloseIcon from "../../icons/CloseIcon";
export default {
  components: {
    WhatsappIcon,
    FacebookIcon,
    ContactIcon,
    CloseIcon
  },

  data() {
    return {
      showOptions: true,
    };
  },
};
</script>

<style scoped>
.instagram-enter-active,
.instagram-leave-active {
  transition: all 0.2s ease;
}
.instagram-enter-from,
.instagram-leave-to {
  opacity: 0;
  transform: translateY(200px);
}
.facebook-enter-active,
.facebook-leave-active {
  transition: all 0.2s ease;
}
.facebook-enter-from,
.facebook-leave-to {
  opacity: 0;
  transform: translateY(100px);
}
.whatsapp-enter-active,
.whatsapp-leave-active {
  transition: all 0.2s ease;
}
.whatsapp-enter-from,
.whatsapp-leave-to {
  opacity: 0;
  transform: translateY(50px);
}
</style>