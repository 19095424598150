<template>
  <div class="flex flex-col justify-center items-center absolute inset-0">
    <button
      class="button-interactive absolute top-4 right-4 font-semibold"
      @click="$store.dispatch('logout')"
    >
      Cerrar Sesión
    </button>
    <div class="auth-container relative">
      <div class="title mx-auto">Verifica tu cuenta</div>

      <p class="mt-6">Te hemos enviado un correo para verificar tu cuenta.</p>

      <primary-button
        class="mt-6 w-full"
        :loading="loadingReload"
        @buttonClicked="loadMe"
        >Actualizar</primary-button
      >

      <div class="flex flex-col">
        <button class="link underline mt-4" @click="resendVerification">
          Reenviar Correo
        </button>

        <div v-if="loadingResend" class="mx-auto mt-2">
          <loader-icon class="h-6 w-6 text-primary-500 mx-auto"></loader-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api";
import { mapState } from "vuex";
export default {
  name: "Unverified",

  data() {
    return {
      loadingReload: false,
      loadingResend: false,
    };
  },

  computed: {
    ...mapState({
      client: "client",
    }),
  },

  created() {
    if (this.client && this.client.verified) {
      this.$router.push({
        name: "wedding",
        params: { id: this.client.weddings[0] },
      });
    }
  },

  methods: {
    async loadMe() {
      this.loadingReload = true;

      try {
        const client = await api.clients.me();
        if (!client.verified) {
          this.$store.dispatch(
            "errorSnack",
            "El cliente aún no está verificado."
          );
        } else {
          this.$store.commit("setClient", client);
          this.$router.push({ name: "wedding_form" });
        }
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingReload = false;
    },

    async resendVerification() {
      this.loadingResend = true;

      try {
        const response = await api.auth.resendVerification();
        this.$store.dispatch("successSnack", response.message);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingResend = false;
    },
  },
};
</script>

