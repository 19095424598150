<template>
  <div class="flex items-center bg-gray-50 absolute inset-0">
    <div v-if="loading" class="flex w-full h-full items-center">
      <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
    </div>
  </div>
</template>

<script>
import api from "../../api";
export default {
  name: "Google Auth",
  
  data() {
    return {
      loading: false,
      googleUser: null,
    };
  },

  created() {
    this.googleAuthCallback();
  },

  methods: {
    async googleAuthCallback() {
      this.loading = true;

      try {
        const response = await api.auth.googleVerification({
          code: this.$route.query.code,
        });
        this.$store.dispatch("login", response);
        if (response.client.weddings.length > 0) {
          this.$router.push({
            name: "wedding",
            params: { id: response.client.weddings[0].id },
          });
        } else {
          this.$router.push({ name: "wedding_form" });
        }
      } catch (error) {
        this.$store.dispatch("errorSnack", error.response?.data?.message);
        this.$router.push({ name: "login" });
      }

      this.loading = false;
    },
  },
};
</script>