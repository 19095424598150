<template>
  <div class="flex flex-col h-screen bg-white p-8">
    <h1>Términos y Condiciones</h1>
    <div class="content text-justify mt-4 text-sm">
      Términos y Condiciones BlueKit México S.A. de C.V. (en lo sucesivo
      “BlueKit”) es propietario y opera el sitio web daisi.mx (en adelante
      “el Sitio Web”), por lo que todo el contenido y los servicios o productos
      que se encuentran disponibles en el Sitio Web son administrados por
      BlueKit. Los presentes Términos y Condiciones (a continuación “los
      Términos”) regulan la relación contractual entre los usuarios (en lo
      sucesivo “el Usuario”) y Bluekit para el uso del Sitio Web, oferta,
      prestación y/o adquisición de todo tipo de servicios o productos (en
      adelante “los Servicios”). Los Usuarios y en su defecto cualquier persona
      que ingrese al Sitio Web manifiesta su conformidad y se obliga a cumplir y
      sujetarse conforme a los presentes Términos y Condiciones. En el caso de
      que no esté de acuerdo con los presentes Términos y Condiciones deberá
      abstenerse de acceder o utilizar el Sitio Web. DEFINICIONES Para
      comprender los términos empleados y facilitar la lectura, a continuación
      se presentan las definiciones que habrán de utilizarse en estos Términos y
      Condiciones, mismas que podrán ser utilizadas en singular o plural sin
      variar su significado: • BlueKit: Propietario y operador del Sitio Web
      daisi.mx quien opera bajo los términos y condiciones acordados a
      través del presente. Cuando se haga mención a BlueKit se hara referencia
      también y en su defecto se incluirá a sus subsidiarias, controladoras,
      partes relacionadas, afiliadas, representantes y/o empleados. • Sitio Web:
      daisi.mx • Términos y Condiciones: Hace referencia al conjunto de
      derechos y obligaciones generados a las Partes que se derivan de la
      relación comercial y uso del Sitio Web como Usuario y/o Cliente. •
      Contenido: Es toda la información, datos, texto, software, sonido,
      fotografías, gráficas, videos, mensajes, productos, servicios y otros
      materiales puestos a disposición y publicados en el Sitio Web. • Cuenta:
      Todas las personas (Usuarios) que deseen adquirir los servicios o
      productos que se encuentran disponibles en el Sitio Web deberán crear una
      cuenta para poder navegar y tener acceso al mismo. Los datos registrados
      en cada cuenta deberán ser verídicos y serán tratados conforme al Aviso de
      Privacidad publicado en el Sitio Web. • Usuarios: La persona que crea una
      cuenta pero que utiliza y navega en el Sitio Web de forma gratuita. Los
      Usuarios no reciben los servicios o productos, salvo que los adquieran a
      través del Sitio Web. Los Usuarios deben ser personas físicas mayores de
      edad (18 años en adelante) o mayores de 13 años que cuenten con la
      autorización del padre o tutor. Las personas morales serán aquellas por
      quienes actúan mediante un representante legal autorizado. • Cliente: Son
      los Usuarios (ya sea persona física o moral) que adquieren los servicios
      que se ofrecen a través del Sitio Web. Las personas físicas deben ser
      mayores de edad (18 años en adelante) o mayores de 13 años que cuenten con
      la autorización del padre o tutor. Las personas morales serán aquellas por
      quienes actúan mediante un representante legal autorizado. Un cliente
      siempre tendrá la calidad de Usuario, pero un Usuario no podrá tener la
      calidad de Cliente, salvo la previa adquisición del servicio
      correspondiente. • Servicios: La creación de invitaciones digitales para
      eventos mediante el Sitio Web, respecto a las cuáles el Cliente solo será
      beneficiario de aquellos derechos que le correspondan conforme a los
      Servicios adquiridos mediante el Sitio Web. • Contrato de Prestación de
      Servicios: La formalización de la relación comercial entre el Cliente y
      BlueKit. • Derechos de propiedad intelectual: Todos los derechos
      relacionados al contenido del Sitio Web, incluido de forma enunciativa
      pero sin limitarse a: textos, notas, graficos, fotos, sonidos, música,
      videos, interactividades, marcas, nombres comerciales, denominaciones,
      derechos de autor y demás. • Marcas: Logotipos, marcas comerciales,
      diseños del Sitio Web, diseño y logotipos de productos y servicios.
      CONDICIONES GENERALES Los Usuarios al momento de crear su cuenta, acceder,
      navegar, utilizar, adquirir o contratar los Servicios mediante el Sitio
      Web aceptan y reconocen que cuentan con capacidad de ejercicio para hacer
      uso del mismo. Por lo que los Usuarios y Clientes aceptan que han leído,
      entendido y por lo tanto se sujetan a los Términos que más adelante se
      definen, reconociendo los Usuarios y Clientes que renuncian a cualquier
      derecho colectivo de demanda. Asimismo, reconocen y aceptan que al
      acceder, hacer uso y/o bien adquirir los Servicios del Sitio Web, se
      someten de forma tácita e incondicional a los presentes Términos y
      Condiciones emitidos y publicados periódicamente por BlueKit, así como al
      Aviso de Privacidad contenido en el Sitio Web. El acceso al Sitio Web es
      gratuito salvo en lo relativo al coste de la conexión a través de la red
      de telecomunicaciones suministrada por el proveedor de acceso contratado
      por los Usuarios. Los servicios ofrecidos a través del Sitio Web se
      encuentran sujetos a costos variables, para los cuales los Usuarios que
      adquieran los Servicios se sujetan a todas las disposiciones establecidas
      en los Términos y Condiciones publicados en el Sitio Web. Los Usuarios al
      crear una cuenta personal dentro del Sitio Web podrán navegar dentro del
      mismo, teniendo acceso al contenido publicado por BlueKit de forma
      gratuita, la creación de la cuenta de cada Usuario así como la navegación
      y uso del Sitio Web estarán sujetos a los Términos y Condiciones aquí
      descritos. Los servicios que otoga BlueKit son exclusivos para los
      Clientes, por lo que los Usuarios solo podrán contar con los Servicios al
      contratar los mismos. Los Usuarios y los Clientes aceptan haber leído
      atentamente los Términos y Condiciones establecidos a través del presente,
      por lo que se obligan a sujetarse a los mismos. REGISTRO Y USO DE CUENTA
      Para la prestación de los Servicios todos los Usuarios deben crear una
      cuenta, mediante la cual proporcionarán información completa y precisa
      correspondiente a su persona o de quien actúan en representación. Será
      obligación de cada Usuario y en su caso Cliente mantener la
      confidencilidad de su cuenta y contraseña, así como de las actividades que
      se generen a partir de la cuenta o cuentas conexas. BlueKit no permite la
      cesión y/o transmisión de cuentas correspondientes al Sitio Web, por lo
      que cada Usuario y en su caso Cliente será responsable del uso que se
      otorgue a su cuenta. Para efectuar el registro correcto de la cuenta, el
      Usuario deberá otorgar la información completa y precisa. Dicha
      información es de uso restringido por lo que será responsabilidad del
      Usuario resguardar los datos de correo electrónico, contraseñas y demás
      información que se otorgue a través de la cuenta creada para el Sitio Web.
      BlueKit no será responsable del uso que cada Usuario otorgue a su cuenta.
      BlueKit no será responsables del uso y destino que otorguen los Usuarios a
      sus cuentas, por lo que en este acto el Usuario y en su defecto el Cliente
      los libera de toda responsabilidad de las consecuencias que se generen con
      motivo de las acciones, negligencia, omisión y/o descuido del Usuario
      sobre su cuenta, navegación y uso a través del Sitio Web. Manifestando el
      Usuario que el uso de su cuenta así como de todos los datos que se generen
      por la misma se encontrarán en su resguardo, siendo este el único
      responsable de las acciones u omisiones así como el uso que se genere por
      la misma. DATOS DE LA CUENTA La política de privacidad del Sitio Web podrá
      ser aplicable en todo momento, por lo que el Usuario reconoce que todos
      los datos que otorgue a través del Sitio Web están condicionadas al
      estricto cumplimiento, manifestando que todos y cada uno de los datos
      proporcionados son verídicos, correspondientes a su persona y a la
      situación real del Usuario. De igual forma el Usuario se obliga a mantener
      toda su información actualizada, obligándose a efectuar y notificar en su
      cuenta de cualquier cambio que considere necesario. Todas las cuentas
      registradas en el Sitio Web son consideradas cuentas personales, es decir
      que corresponden solo a un Usuario, por lo que este ultimo manifiesta que
      todos los datos proporcionados en el Sitio Web fueron introducidos
      directamente por el Usuario, siendo este último el único responsable de
      las manifestaciones falsas o inexactas que otorgue a través de su Cuenta
      en el Sitio Web, así mismo será responsable de las consecuencias y en su
      defecto controversias que dichas falsedades o incorrectas declaraciones
      causen al Sitio Web y/o a cualquier otro tercero. SEGURIDAD DE LA CUENTA
      El Usuario y en su defecto el Cliente se obliga a dar aviso inmediato de
      cualquier uso no autorizado de su cuenta, así como de cualquier acto que
      considere sea conceptualizado como una violación de seguridad. Aceptando
      el Usuario, ser el único responsable de lo que se genere con motivo del
      uso de su cuenta, por lo que BlueKit no será responsable de perdida, daño,
      gasto o costo (incluidos, entre otros los honorarios legales) en los que
      pueda incurrir el Usuario y/o Cliente como resultado de que otra persona o
      entidad use su contraseña o cuenta con o sin su conocimiento y/o
      consentimiento. El Usuario y/o Cliente será el único responsable de las
      perdidas, daños, gastos y costos (incluidos entre otros, los honorarios
      legales) incurridos por BlueKit o un tercero debido a que otra persona use
      su cuenta, por tal motivo el Usuario y/o Cliente será responsable de sacar
      en paz y a salvo a BlueKit, así como a cualquier otro tercero de las
      controversias que puedan surigir en su contra por las acciones u omisiones
      del Usuario y/o Cliente, así como por el uso y destino que se le de a su
      Cuenta. CONTRATACIÓN DE SERVICIOS La contratación de los Servicios se
      realizará siguiendo las instrucciones que se indiquen en el Sitio Web. Una
      vez que el Usuario haya finalizado el proceso de compra conforme a los
      registros y formularios del Sitio Web se entenderá que se ha formalizado
      la relación comercial entre el Usuario ahora Cliente y BlueKit (denominado
      como el Contrato de Prestación de Servicios). Una vez que el Cliente haya
      recibido su confirmación el Contrato de Prestación de Servicios se
      entenderá como confirmado y vigente, aceptando el Cliente sujetarse a las
      condiciones establecidas conforme a dicho contrato y a la dinámica del
      Sitio Web. Será responsabilidad del Cliente revisar los datos contenidos
      en los registros y formularios de adquisición del servicio, por lo que en
      caso de que exista cualquier error en dicho registro o formulario deberá
      de modificar la información mediante el Sitio Web o dar aviso de forma
      inmediata mediante correo electrónico, en caso contrario y ante la omisión
      del Cliente, se entenderá que todos los datos proporcionados y
      establecidos en el registro o formulario son correctos, otorgando el
      Cliente su conformidad con lo establecido. En caso de que existan errores
      de datos en los registros y formularios y el Cliente no manifieste ninguna
      solicitud de cambio, será responsabilidad de este último las consecuencias
      que se generen por su omisión. Los Servicios contratados tendrán una
      vigencia de hasta 6 meses posteriores a la fecha del evento señalado en la
      invitación digital que se contrate. El Contrato de Prestación de Servicios
      solo corresponderá a los servicios contratados mediante el Sitio Web, es
      decir, el Usuario solo será beneficiario de aquellos derechos que le
      correspondan conforme a los Servicios adquiridos. El Usuario y en su
      defecto el Cliente podrán adquirir los Servicios mediante el pago que
      corresponda a la tarifa publicada en el Sitio Web, el pago o los pagos que
      correspondan serán cargados a su tarjeta de crédito y/o débito. Los
      alcances de los Servicios serán publicados en el Sitio Web, información
      que tendrá los detalles, así como la descripción general de los Servicios
      ofrecidos por BlueKit. Los Servicios disponibles para la contratación en
      línea se ofertan únicamente para uso personal, quedando prohibido la
      reventa de estos, salvo pacto específico en contrario por BlueKit. BlueKit
      determinará libremente y de tiempo en tiempo, la cantidad de los Servicios
      que el Usuario y/o Cliente podrá contratar y adquirir a través del Sitio
      Web, teniendo la facultad de cancelar o reducir, cuando a su juicio se
      incurra a una violación a lo establecido en los presentes Términos y
      Condiciones y/o generen un perjuicio a su operación. BlueKit ofrece los
      Servicios con un nivel de competencia y diligencia razonable desde un
      punto de vista profesional, sin embargo, no ofrece ningún tipo de garantía
      en relación con estos. BlueKit no manifiesta ninguna representación o
      garantía de ningún tipo, expresa o implícita, en relación con la operación
      del Sitio Web, información, contenido, materiales y/o Servicios. Ni
      BlueKit, ni sus proveedores, socios comerciales o distribuidores ofrecen
      garantías específicas sobre los Servicios e información compartida en el
      Sitio Web; BlueKit excluye todas las garantías en la medida que las leyes
      vigentes lo permitan. BlueKit no asume ni asumirá ningún tipo de
      responsabilidad frente a ninguna persona, derivada o que pudiera derivarse
      por la información compartida en el Sitio Web, prestación de Servicios,
      consultas, aclaraciones y/o cualquier otra clase de respuesta otorgada por
      parte de BlueKit por cualquier medio de comunicación. El Usuario y/o
      Cliente se obliga a dejar en paz y a salvo a BlueKit, a sus accionistas,
      subsidiarias, afiliadas, funcionarios, directores, empleados, asesores,
      apoderados, representantes y/o cualquier persona relacionada con esta, de
      cualquier responsabilidad que pudiera imputarse en virtud y/o en relación
      con el Sitio Web, la prestación de los Servicios o cualquier otro derivado
      de los presentes Términos y Condiciones. CONDICIONES ESPECIFICAS FORMAS DE
      PAGO El Cliente acepta y reconoce su deseo de pagar el precio que se
      indique en el Sitio Web por la adquisición de los Servicios, el cual
      incluye el Impuesto al Valor Agregado (I.V.A.), según sea el porcentaje
      y/o términos establecidos en las leyes fiscales aplicables y vigentes, al
      momento de la compra. BlueKit a su entera discreción podrá cambiar el
      precio de cada uno de los Servicios dentro del Sitio Web en cualquier
      momento. El pago de los servicios puede realizarse por medio de los
      siguientes métodos de pagos: • Pagos por medio de la plataforma de Stripe.
      BlueKit por medio del portal https://stripe.com/ realizará cargos a la
      tarjeta de débito o crédito del Usuario, previa autorización, por concepto
      de pago de los Servicios, cargo a meses sin intereses, cargos de contado o
      cargos recurrentes. El Usuario reconoce y acepta que Stripe se reserva el
      derecho de declinar una transacción por cualquier motivo y, que en su
      caso, ni Stripe ni BlueKit serán responsables de dichos pagos declinados.
      Al realizarse los pagos por medio de Stripe, toda la captura de
      información de la tarjeta de débito o crédito se lleva a cabo en dicha
      plataforma y servidores del procesador de Stripe, bajo sus propios
      estándares de seguridad. El Usuario al utilizar el Sitio Web reconoce y
      acepta sin reservas los Términos y Condiciones, así como el Aviso de
      Privacidad de Stripe, dejando a BlueKit libre de cualquier responsabilidad
      y reclamación por el manejo de información. • Pago a meses sin intereses.
      Los pagos a meses sin intereses únicamente se encuentran permitidos al ser
      realizados con tarjeta de crédito y solamente para ciertos Servicios
      dentro del Sitio Web. Dichos pagos generan un cobro por el servicio de
      diferirlo. Bluekit podrá ofrecer al Usuario cupones de descuento, mismos
      que se aplicarán únicamente en los Servicios contratados en el Sitio Web.
      Los cupones estarán limitados a las aplicaciones de ciertos métodos de
      pago, mismos que serán designados por BlueKit. Los cupones serán por
      tiempo determinado, vigencia que será designada por BlueKit en todo
      momento. Los cupones aplicarán a Servicios especificos, indicados por
      BlueKit. FACTURACIÓN En caso de que el Cliente requiera comprobante fiscal
      (en lo sucesivo “Factura”) por la contratación de los Servicios a través
      del Sitio Web, el Usuario deberá de proporcionar al correo electrónico
      admin@daisi.mx, a más tardar el día siguiente de la contratación de
      los Servicios, los datos y documentos necesarios con el fin de que BlueKit
      pueda realizar y enviar al Cliente la Factura correspondiente. Sin
      embargo, el Cliente únicamente podrá solicitar la Factura durante el mes
      en el que realizo la compra de los Servicios. DEVOLUCIONES Y CANCELACIONES
      El Usuario reconoce que los cargos que se realicen a tarjetas de débito o
      crédito, así como los pagos realizados mediante SPEI (transferencia
      electrónica), STRIPE o pago en efectivo, con motivo de los Servicios
      contratados, no serán objeto de devolución. Por lo que, al realizar los
      pagos correspondientes a los Servicios a contratar el Usuario se sujeta y
      acepta sin reservas los Términos y Condiciones, así como Aviso de
      Privacidad de los proveedores de servicios relacionados con dichos pagos.
      En la medida que la ley aplicable lo permita, ningún pago realizado será
      reembolsable y no se otorgarán reembolsos ni créditos por BlueKit.
      CONFIDENCIALIDAD BlueKit se compromete a mantener confidencial la
      información que reciba del Usuario y/o Cliente conforme a las
      disposiciones legales aplicables en los Estados Unidos Mexicanos. BlueKit
      podrá divulgar la información del Usuario y/o Cliente a las autoridades
      competentes en términos de la legislación aplicable; cualquier
      transferencia de sus datos personales sin consentimiento se realizará de
      acuerdo con el artículo 37 de la Ley Federal de Protección de Datos
      Personales en Posesión de los Particulares. PROPIEDAD INTELECTUAL Todo el
      contenido del Sitio Web incluyéndose pero sin limitarse a marcas, avisos
      comerciales, derechos de autor, logotipos, diseños conexos, diseños del
      Sitio Web, software, servicios y en general todos los derechos de
      propiedad intelecual que se deriven del Sitio Web y de los servicios que
      se otorguen a través del mismo son propiedad exclusiva de BlueKit.
      Reconociendo el Usuario y en su defecto el Cliente, que el hacer uso del
      Sitio Web y ser miembro activo del mismo no le otorga ningun tipo de
      derecho sobre la Propiedad Intelectual que se genere dentro del mismo,
      obligándose a respetar todos los derechos que emanen del Sitio Web y los
      servicios que se otorgan mediante el mismo. El contenido del Sitio Web se
      le proporciona al Usuario y/o Cliente es para su información y uso
      personal únicamente y no puede ser utilizado, reproducido, distribuido,
      transmitido, difundido, mostrado, vendido, licenciado o explotado de otra
      manera para cualquier otro propósito. A través del presente no se otorga
      ningun tipo de cesión y/o autorización a favor de los Usuarios ni
      Clientes, obligándose a respetar los derechos que se deriven por toda la
      Propiedad Intelectual del Sitio Web, los productos y servicios ligados a
      este último. El Usuario reconoce que el Sitio Web y/o cualquier software
      que sea necesario para ser utilizado en conexión con el Servicio y/o el
      Sitio Web, pueden contener información de terceros ya sea confidencial o
      no que incluso puede estar protegida por las leyes de propiedad
      intelectual y otras leyes aplicables. Por lo tanto, el Usuario se
      compromete a no modificar, copiar o reproducir, rentar, prestar, vender,
      distribuir y/o crear obras del Servicio y del Sitio y/u obras derivadas
      y/o basadas en el Servicio y en el Sitio Web, en su totalidad o en parte.
      Todo el Contenido y el Sitio Web están amparados por derechos de Propiedad
      Intelectual. Todos los derechos se encuentran reservados para BlueKit.
      ENLACES DE TERCEROS El Sitio Web podrá proporcionar direcciones web y
      enlaces directos a sitios web y/o a otros recursos de terceros, sobre los
      cuales BlueKit no tiene control o responsabilidad sobre el contenido
      lícito, disponibilidad, accesibilidad o veracidad de la información en
      ellos. De la misma forma, BlueKit no será responsable por pérdidas o daños
      causados directos o indirectos, o supuestamente causados por el contenido,
      productos o servicios disponibles a través de cualquiera de dichos sitios
      o recursos. El Usuario y en su defecto el Cliente acepta los Términos y
      Condiciones, así como el Aviso de Privacidad de terceros. RESPONSABILIDAD
      BlueKit, se compromete a mantener el Sitio Web, así como sus contenidos,
      de forma razonablemente correcta y actualizada, teniendo los controles de
      seguridad que a su criterio considere necesarios, buscando siempre la
      seguridad del Usuario y/o Cliente pero no podrá de ninguna manera
      garantizar al Usuario que los contenidos de éstas carezcan de errores,
      defectos, malware y/o virus. El Sitio Web podrá eventualmente no estar
      disponible debido a dificultades técnicas o fallas de Internet, o por
      cualquier otra circunstancia ajena a BlueKit; en tales casos se procurará
      restablecerlo con la mayor celeridad posible sin que por ello pueda
      imputársele a BlueKit algún tipo de responsabilidad, por lo que BlueKit no
      será responsable por ningún error u omisión contenidos en su Sitio Web.
      BlueKit no será responsable por ningún daño o perjuicio derivado del uso
      del Sitio Web, incluyendo de manera enunciativa más no limitativa, los
      daños causados por malware, virus, directos, indirectos, morales,
      especiales o consecuenciales que resulten del uso del Sitio Web o de la
      imposibilidad para usarlo o cualquier información incorrecta o incompleta
      que se pueda mostrar en el Sitio Web. Asimismo, BlueKit no será
      responsable, en ningún caso, de ningún daño indirecto, especial,
      incidental, emergente, punitivo o ejemplar (incluidos, entre otros,
      pérdida de actividad mercantil, lucro cesante, pérdida de datos, uso,
      ingresos u otra ventaja económica) que surja en relación con el Sitio Web.
      USO ADECUADO El Usuario y en su defecto el Cliente se compromete a
      utilizar y acceder al Sitio Web conforme a lo establecido en las leyes, la
      moral, las buenas costumbres y el orden público de los Estados Unidos
      Mexicanos, por lo que se obliga a abstenerse de utilizar el Sitio Web y/o
      de realizar actos con fines o efectos ilícitos, lesivos de derechos e
      intereses de terceros o con fines clandestinos y/o que de cualquier forma
      puedan dañar, inutilizar, sobrecargar o deteriorar el Sitio Web y/o
      impedir su uso normal de los Usuarios y Clientes. Lo anterior incluye
      cualquier conducta por parte del Cliente y/o Usuario que vaya en contra
      del Sitio Web, así como en contra de cualquier tercero relacionado al
      Sitio Web o que sea parte de este. VIOLACIONES AL SISTEMA O BASES DE DATOS
      No está permitida ninguna acción o uso de dispositivo, software, u otro
      medio tendiente a interferir tanto en las actividades y operatoria del
      Sitio Web, como en las ofertas, descripciones, cuentas o bases de datos.
      Cualquier tentativa o actividad violatoria o contraria a las leyes sobre
      derecho de propiedad intelectual y/o a las prohibiciones estipuladas en
      estos Términos y Condiciones harán a su responsable de las acciones
      legales pertinentes, y a las sanciones previstas por este documento, así
      como lo hará responsable de indemnizar los daños y perjuicios ocasionados.
      ACCESO A RED Y COMPATIBILIDAD El Usuario es el único responsable de
      obtener el acceso a la red de datos necesario para poder nevegar en el
      Sitio Web. Cuando en ese sentido, si el Usuario accede desde un
      dispositivo inalámbrico, podrán aplicarse las tarifas y tasas de datos y
      mensajes de su red móvil, de acuerdo al proveedor de dicho servicio. El
      Usuario es el responsable por actualizar el software y hardware compatible
      o los dispositivos necesarios para acceder al Sitio Web y cualquier
      actualización de este. BlueKit no garantiza que el Sitio Web, o cualquier
      parte de él, funcionen en cualquier hardware o dispositivo particular. NO
      RELACIÓN ENTRE LAS PARTES Estos Términos y Condiciones no crean en ningún
      momento contrato de sociedad, de mandato, de franquicia, y/o relación
      laboral entre BlueKit y el Usuario y/o Cliente. QUEJAS E INCONFORMIDADES
      En casos de inconformidad o quejas sobre el contenido de éstos Términos y
      Condiciones, el Usuario y/o Cliente tendrá un máximo de 30 (treinta) días
      naturales desde la presentación para manifestar su inconformidad, misma
      que deberá hacerse de acuerdo a los presentes Términos y Condiciones.
      Cualquier inconformidad o queja deberá comunciarse a admin@daisi.mx.
      Las Partes acuerdan y se obligan a notificarse a través de los medios de
      contacto de texto que para tal efecto hayan dispuesto ambas Partes, por lo
      menos 5 (cinco) días hábiles antes de ejercer cualquier derecho en su
      favor derivado del incumplimiento de la otra Parte. La Parte afectada en
      un término no mayor a 5 (cinco) días hábiles, podrá manifestar su
      objeción, establecer su posición respecto a la alegación de
      incumplimiento, o bien proceder al cumplimiento de la prestación alegada.
      En caso de que la parte incumplida no corrigiere la falta de que se trate,
      la Parte afectada quedará en libertad de proceder conforme a su interés y
      derecho, en los términos de estos Términos y Condiciones. INDEMNIZACIÓN El
      Usuario se obliga ilimitadamente a sacar en paz y a salvo, y, en su caso,
      a indemnizar a BlueKit, representantes, empleados, clientes, usuarios,
      proveedores y/o consultores, independientemente de que medie o no
      reclamación de tercera persona, por cualquier daño y/o perjuicio derivado
      de o relacionado con: (i) el incumplimiento de cualquiera de las
      obligaciones a su cargo establecidas en los Términos y Condiciones; (ii)
      violaciones a Derechos de Propiedad Intelectual de BlueKit y/o de
      terceros, y; (iii) la falta de veracidad del contenido que entregue,
      publique o envíe por cualquier medio a BlueKit. BlueKit no asume
      responsabilidad alguna por las violaciones a las leyes aplicables,
      causadas por el Usuario y/o Cliente, en la utilización del Sitio Web de
      forma inadecuada o contraria a los presentes Términos y Condiciones. En
      caso de que el Usuario y/o Cliente incumpla cualquiera de las obligaciones
      a su cargo establecidas en la presente Cláusula, BlueKit podrá ejercer las
      acciones que en su caso procedan en contra del Usuario y/o Cliente, de
      conformidad con lo establecido en los presentes Términos y Condiciones y
      en las disposiciones legales aplicables. AVISO DE PRIVACIDAD Toda la
      información que BlueKit recabe del Usuario y/o Cliente es tratada con
      absoluta confidencialidad conforme a lo establecido en la Ley Federal de
      Protección de Datos Personales en Posesión de los Particulares. Consulte
      el Aviso de Privacidad publicado en el Sitio Web para conocer más sobre la
      protección de sus datos personales. MODIFICACIONES BlueKit se reserva el
      derecho de modificar unilateralmente los presentes Términos y Condiciones.
      Es responsabilidad del Usuario y/o Cliente revisar periódicamente estos
      Términos y Condiciones. El uso continuo del Sitio Web supone la aceptación
      de los Términos y Condiciones, así como de sus modificaciones. El Usuario
      y/o Cliente manifiesta entender a la perfección los presentes Términos y
      Condiciones y, por tanto, se atiene a ellos comprendiendo las
      consecuencias legales que éstos conllevan. En el caso de que alguna de las
      disposiciones contenidas en los presentes Términos y Condiciones sea
      declarada nula, se procederá a retirar o sustituir la misma. En cualquier
      caso, dicha declaración de nulidad no afectará a la validez del resto de
      disposiciones establecidas en los presentes Términos y Condiciones. Todo
      Usuario que no esté de acuerdo con las modificaciones efectuadas por
      BlueKit deberá abstenerse de utilizar el Sitio Web. ENCABEZADOS,
      INTEGRIDAD E INTERPRETACIÓN BlueKit ha establecido los títulos de las
      cláusulas de estos Términos y Condiciones, con el fin exclusivo de
      facilitar la lectura de este documento. De acuerdo a lo anterior, las
      Partes han tenido a bien acordar el que dichos títulos no necesariamente
      definen ni limitan el contenido ni el alcance de lo pactado en dicha
      cláusula. Para fines de interpretación y/o aplicación de estos Términos y
      Condiciones se analizará su contenido atendiendo a su objeto, la buena fe
      de las partes, la integridad de las cláusulas y a los usos y costumbres.
      En caso de que surja una causal de nulidad determinada por autoridad
      competente, la misma afectará únicamente a la cláusula a la que
      específicamente se hubiere incurrido en el vicio correspondiente dejando
      lo restante de este instrumento en pleno vigor. AVISOS, NOTIFICACIONES Y
      DOMICILIOS Todos los avisos y notificaciones que el Usuario deba o desee
      hacer en relación con estos Términos y Condiciones de Uso, deberán ser por
      correo electrónico a: admin@daisi.mx. Para tales efectos y hasta en
      tanto se notifiquen nuevos domicilios en la forma antes indicada, el
      domicilio del Usuario se entenderá el proporcionado al momento de generar
      su Cuenta de Usuario. CASO FORTUITO O FUERZA MAYOR Ante situaciones de
      caso fortuito y/o fuerza mayor que impidan o imposibiliten el cumplimiento
      de las obligaciones por parte de BlueKit, este último no será responsable.
      Por causa de fuerza mayor se entenderá cualquier situación o
      acontecimiento imprevisible y excepcional, o independiente de la voluntad
      de las partes contratantes y en particular (sin limitación) lo siguiente:
      huelgas, cierres patronales o cualquier otra acción colectiva; conmoción
      civil, disturbios, invasiones, ataques terroristas o amenazas de ataques
      terroristas, guerra (declarada o no) o amenaza de guerra; incendios,
      explosiones, tormentas, inundaciones, terremotos, deslizamientos,
      epidemias u otros desastres naturales; la imposibilidad de usar el
      ferrocarril, transporte marítimo, aéreo, o terrestre a motor u otros
      medios de transporte público o privado; la imposibilidad de uso de las
      redes de telecomunicaciones públicas o privadas, y leyes, decretos,
      reglamentos o restricciones de cualquier gobierno. JURISDICCIÓN Acuerdan
      las Partes que para las controversias, litigios y/o cualquier otra
      contingencia que surja de la celebración, implementación, interpretación
      y/o ejecución del presente instrumento, han de sujetarse a lo dispuesto
      por la legislación Mexicana, así como a la jurisdicción y competencia de
      los tribunales de la cuidad de Monterrey, Nuevo León; renunciando
      expresamente a cualquier otro fuero que, en virtud de sus domicilios,
      presentes o futuros, lugar de la consumación de las actividades objeto del
      presente documento y/o nacionalidades pudiera corresponderles. Habiendo
      leído y entendido cada uno de los términos y condiciones, el Usuario
      manifiesta su total aceptación y sujeción a cualquier obligación de ella
      derivada, al usar el Sitio Web. En casos de inconformidades, preguntas,
      comentarios o cualquier otra comunicación relacionada con cualquiera de
      los aspectos contenidos en estos Términos y Condiciones, el Usuario deberá
      contactarse al correo electrónico: admin@daisi.mx.
    </div>

    <h1 class="mt-4">Aviso de Privacidad</h1>
    <div class="content text-justify mt-4 text-sm pb-16">
      BlueKit México S.A. de C.V. (en lo sucesivo “BlueKit”) con domicilio
      ubicado en Cabo Catoche 3649, Colonia Rincón de la Primavera, C.P. 67174,
      Guadalupe, Nuevo León, es el responsable del tratamiento de los datos
      personales que nos proporcione a través de nuestro sitio web
      (https://daisi.mx), redes sociales y/o de cualquier otra forma,
      mismos que serán protegidos en términos de la Ley Federal de Protección de
      Datos Personales en Posesión de los Particulares. Recopilación de la
      Información La recaudación de datos personales, datos de contacto y en
      general de los datos contenidos por los usuarios dentro del sitio web se
      efectuará de forma directa cuando el usuario los proporcione y/o bien
      mediante cualquier medio físico o electrónico y/o bien a través de otras
      fuentes que estén permitidas por la ley. Los datos recopilados mediante el
      sitio web serán tratados de forma directa por BlueKit. Recibimos y
      almacenamos todos los datos que usted nos proporciona en relación con los
      servicios que se otorgan a través del sitio web. Incluyendo de manera
      enunciativa pero no limitativa, nombre, edad, correo electrónico,
      dirección, teléfono, RFC, datos bancarios. Esta información sirve para
      registrarlo como usuario del sitio web de BlueKit, así como para brindarle
      nuestros productos y servicios. Uso de la Información. En términos
      generales la información recabada puede ser utilizada de manera
      enunciativa pero no limitativa, para los siguientes fines: • Ofrecerle
      productos y servicios, a través de nuestro sitio web
      (https://daisi.mx), de manera física, telefónica, electrónica o por
      cualquier otra tecnología; • Registrarlo como usuario del sitio web de
      BlueKit. • La personalización de los servicios adquiridos en el sitio web.
      • Para integrar expedientes, bases de datos y sistemas necesarios para
      llevar a cabo las operaciones y servicios correspondientes; • Detectar y
      en su defecto evitar la ejecución de actividades que sean consideradas
      prohibidas o ilegales. • Mantener comunicación constante con el Usuario,
      permitiendo otorgarle vías de comunicación y ayuda en caso de problemas
      con el uso del sitio web, además de enviar al usuario promociones,
      notificaciones y en general cualquier información relacionada con el sitio
      web. • Cualquier otra acción que sea necesaria para cumplir con los
      intereses de BlueKit respecto al acuerdo que haya llegado con el Titular
      de la Información. Transferencia de datos personales La información del
      Usuario es vital para el otorgamiento de los servicios ofrecidos, por lo
      cual es necesaria la transmisión de dicha información a terceros que
      fungen como empleados o proveedores de BlueKit, quienes a su vez se
      obligan al resguardo de la información contenida del Usuario mediante el
      presente Aviso de Privacidad. El usuario a través de la aceptación de este
      Aviso de Privacidad otorga su consentimiento para que su información pueda
      ser otorgada a los empleados o proveedores de BlueKit. La información del
      Usuario podrá ser compartida a través de: • Representantes y empleados de
      BlueKit para la operación y desarrollo del servicio a través del Sitio
      Web, mediante la recopilación de base de datos que generarán una mejora en
      la prestación de los servicios. • Proveedores de servicios y todos
      aquellos proveedores externos de BlueKit que trabajan de forma conjunta
      para la operación y el desarrollo de los servicios. Los proveedores de
      servicios otorgarán sus servicios para brindar al Usuario los servicios de
      marketing, servicios TI, publicidad, infraestructura y demás elementos
      necesarios para la ejecución de los servicios. • Socios comerciales,
      quienes podrán tener acceso a las estadísticas del Usuario en relación al
      comportamiento comercial y funcional del Sitio Web. • Transmisión
      comercial. En caso de que exista alguna transmisión, venta y/o cesión
      BlueKit podrá ceder, sin necesidad de autorización previa o cualquier otra
      aprobación, la información del Usuario al tercer adquiriente, quien se
      comprometerá a respetar y resguardar la información con el grado de
      confidencialidad necesario. Seguridad de la Información. Contamos con
      medidas administrativas, digitales, físicas y de gestión razonables y
      aceptables para proteger su Información contra pérdidas, robos y acceso no
      autorizado, uso y modificación. Con estas medidas pretendemos otorgar un
      nivel de seguridad adecuado a los riesgos que pudiera tener el uso de la
      Información. Estas medidas tienen como objetivo brindar un nivel de
      seguridad adecuado a los riesgos de tratar la información personal. No
      obstante lo anterior, es importante que se proteja contra el acceso no
      autorizado de terceros a su nombre de Usuario, contraseña, computadoras y
      demás dispositivos a través de los cuales puede acceder al sitio web. Cada
      usuario en su defecto será responsable de salvaguardar los datos,
      contraseñas y cualquier otra información relevante a su cuenta establecida
      en el sitio web. Datos personales sensibles BlueKit no recaba ningún tipo
      de dato personal sensible como lo son origen racial o étnico, estado de
      salud, presente y futuro, información genética, creencias religiosas,
      filosóficas o morales, afiliación sindical, opiniones políticas o
      preferencias sexuales a los que refiere el artículo 3 fracción VI de la
      Ley Federal de Protección de Datos Personales en Posesión de Particulares.
      Derechos ARCO En cualquier momento podrá ejercer los derechos de acceso,
      rectificación, cancelación y oposición conocidos como “Derechos ARCO”, en
      los siguientes términos: Acceso y rectificación. Podrá solicitar que se le
      haga saber qué datos personales conserva BlueKit y/o pedir su
      rectificación. Cancelación. Podrá solicitar la cancelación o eliminación
      de sus datos personales en posesión de BlueKit. Hay casos en los que, por
      disposición de ley, los datos personales no pueden ser cancelados.
      Oposición. Podrá oponerse al tratamiento que se les da a sus datos
      personales. Para lo anterior, deberá enviar una solicitud que cumpla con
      los siguientes requisitos: • Incluir en la solicitud nombre completo,
      teléfono, domicilio o correo electrónico para oír y recibir
      notificaciones, así como petición en concreto y copia de su identificación
      oficial. • La solicitud debe de ser enviada al correo electrónico
      admin@daisi.mx. En caso de que la información proporcionada sea
      errónea o insuficiente, o bien, no cumpla con los requisitos aludidos, se
      le notificara al correo electrónico que nos proporcione, dentro de los 5
      días hábiles siguientes a la recepción de la solicitud, para requerirle
      que aporte los elementos o documentos necesarios para dar trámite a la
      misma. Contará con 10 días hábiles para atender el requerimiento, contados
      a partir del día siguiente en que lo haya recibido. De no dar respuesta en
      dicho plazo, se tendrá por no presentada la solicitud correspondiente.
      BlueKit le comunicará la determinación adoptada, en un plazo máximo de 20
      días hábiles contados desde la fecha en que se recibió la solicitud, a
      efecto de que, si resulta procedente, haga efectiva la misma dentro de los
      quince 15 días hábiles siguientes a que se comunique la respuesta. La
      respuesta se dará vía electrónica a la dirección de correo que se
      especifique en su petición inicial. Cambios al presente Aviso de
      Privacidad BlueKit se reserva el derecho, bajo su exclusiva discreción, de
      cambiar, modificar, agregar o eliminar partes del presente Aviso de
      Privacidad en cualquier momento. BlueKit mantendrá su Aviso de Privacidad
      Integral vigente en el sitio web (https://daisi.mx). Le recomendamos
      visitar de forma periódica esta página con la finalidad de informarse si
      realiza algún cambio al presente aviso. Aceptación El presente Aviso de
      Privacidad constituye un acuerdo válido entre el usuario y BlueKit. Si
      utiliza nuestro Sitio Web y las herramientas que se encuentran en él, nos
      proporciona algún dato o contrata nuestros servicios significa que ha
      leído, entendido, está de acuerdo y otorga consentimiento expreso con los
      términos aquí́ establecidos.
    </div>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue";
export default {
  name: "Terms Conditions",

  setup() {
    useHead({
      titleTemplate: "%s | Términos y Condiciones, Aviso de Privacidad",
    });
  },
};
</script>