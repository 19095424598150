<template>
  <div class="flex items-center">
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteElement"
      ></delete-modal>
    </transition>

    <form class="flex flex-col w-full">
      <custom-label
        :for="model + '_' + field + '_' + id"
        :value="title"
        :details="details"
      ></custom-label>

      <div class="flex items-center">
        <custom-input
          class="mr-4"
          :id="model + '_' + field + '_' + id"
          type="text"
          v-model="form[field]"
          :disabled="!edit"
          @input="restrictInput"
        ></custom-input>

        <div v-if="createEnabled" class="flex">
          <button
            class="button-interactive text-error-500 mr-4"
            @click.prevent="$emit('cancel')"
          >
            <close-icon class="w-4 fill-current" />
          </button>

          <button
            type="submit"
            class="button-interactive text-success-500"
            @click.prevent="create()"
          >
            <check-icon class="w-8 fill-current" />
          </button>
        </div>

        <div v-else-if="!edit" class="flex">
          <button
            v-if="active"
            class="button-interactive"
            @click.prevent="edit = true"
          >
            <edit-icon class="w-4" />
          </button>

          <button
            v-if="!loadingDelete && deleteEnabled && active"
            class="w-6 fill-current button-interactive text-error-500 ml-4"
            @click.prevent="openDeleteModal()"
          >
            <trashcan-icon />
          </button>
        </div>

        <div v-else class="flex">
          <button
            class="button-interactive text-error-500 mr-4"
            @click.prevent="
              edit = false;
              form[field] = value;
            "
          >
            <close-icon class="w-4 fill-current" />
          </button>

          <button
            type="submit"
            class="button-interactive text-success-500"
            @click.prevent="update()"
          >
            <check-icon class="w-8 fill-current" />
          </button>
        </div>
      </div>

      <validation-errors :errors="errorFor(field)"></validation-errors>
    </form>
  </div>
</template>

<script>
import api from "../../api";
import validationErrors from "../../mixins/validationErrors";
import deleteMixin from "../../mixins/deleteMixin";
import { is422 } from "../../utils/response";
export default {
  name: "WC String",

  data() {
    return {
      form: {},
      edit: this.createEnabled ? true : false,
      loading: false,
      loadingDelete: false,
    };
  },

  props: {
    field: String,
    value: String,
    title: String,
    details: String,
    id: Number,
    model: String,
    resource: Object,
    createEnabled: Boolean,
    deleteEnabled: Boolean,
    createForm: Object,
    regex: String,
    active: Boolean,
  },

  mixins: [validationErrors, deleteMixin],

  created() {
    this.form[this.field] = this.value;
  },

  methods: {
    async create() {
      this.errors = {};
      const request = this.createForm;
      request[this.field] = this.form[this.field];

      try {
        const response = await api[this.resource.api].create(request);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("create", response[this.model]);
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }
    },

    async update() {
      this.errors = {};
      this.loading = true;

      try {
        const response = await api[this.resource.api][this.resource.name](
          this.id,
          this.form
        );
        this.$store.dispatch("successSnack", response.message);
        this.$emit(this.resource.name, this.form[this.field]);
        this.edit = false;
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
    },

    async deleteElement() {
      this.closeDeleteModal();
      this.loadingDelete = true;

      try {
        const response = await api[this.resource.api].delete(this.id);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("delete", this.id);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingDelete = false;
    },

    restrictInput(event) {
      if (!this.regex) {
        return;
      }

      let regex = new RegExp(this.regex);

      if (regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(regex, "");
        this.form[this.field] = event.target.value;
      }
    },
  },
};
</script>