<template>
  <div class="flex flex-col">
    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="removeGuest"
      ></delete-modal>
    </transition>

    <form class="flex flex-col w-full relative">
      <custom-label
        for="guestName"
        value="Nombre del invitado"
        details="(Selecciona los invitados que podrán ver el evento)"
      ></custom-label>

      <custom-input
        class="mr-4"
        id="guestName"
        type="text"
        v-model="searchText"
        @input="search"
      ></custom-input>

      <div
        v-if="guests && guests.length > 0"
        class="flex flex-col bg-white z-50 top-24 p-4 left-0 w-full shadow-lg rounded-lg absolute"
        v-closable="closeSearch"
      >
        <div
          v-for="guest in filteredGuests"
          :key="guest.id"
          class="flex flex-col sm:flex-row justify-between py-2"
        >
          <p>{{ guest.name }} {{ guest.lastName }}</p>
          <secondary-button @buttonClicked="addGuest(guest.id)"
            >Agregar</secondary-button
          >
        </div>
      </div>
    </form>

    <div class="flex flex-wrap w-full">
      <div
        v-for="exclusiveGuest in exclusiveGuests"
        :key="exclusiveGuest.id"
        class="flex px-2 py-1 rounded bg-gray-100 mt-2 mr-2"
      >
        <p>{{ exclusiveGuest.name }} {{ exclusiveGuest.lastName }}</p>
        <button
          class="w-4 fill-current button-interactive text-error-500 ml-2"
          @click.prevent="
            guestToDelete = exclusiveGuest.id;
            openDeleteModal();
          "
        >
          <trashcan-icon />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api";
import SecondaryButton from "../ui/SecondaryButton.vue";
import deleteMixin from "../../mixins/deleteMixin";
export default {
  components: { SecondaryButton },
  name: "Exclusive Guests",

  data() {
    return {
      guests: null,
      searchText: null,
      guestToDelete: null,
    };
  },

  props: {
    exclusiveGuests: Array,
    eventId: Number,
    weddingId: Number,
  },

  computed: {
    filteredGuests() {
      if (!this.guests || !this.exclusiveGuests) {
        return [];
      }

      return this.guests.filter((guest) => {
        return !this.exclusiveGuests.find(
          (exclusiveGuest) => exclusiveGuest.id === guest.id
        );
      });
    },
  },

  mixins: [deleteMixin],

  methods: {
    async search(event) {
      if (event.target.value == "") {
        this.guests = null;
        return;
      }

      const form = {
        name: event.target.value,
        weddingId: this.weddingId,
      };

      try {
        this.guests = await api.guests.search(form);
      } catch (error) {}
    },

    async addGuest(id) {
      const form = {
        guestId: id,
      };

      try {
        const response = await api.events.addGuest(this.eventId, form);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("addGuest", response.guest);
        this.guests = null;
        this.searchText = null;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    async removeGuest() {
      this.closeDeleteModal();

      const form = {
        guestId: this.guestToDelete,
      };

      try {
        const response = await api.events.removeGuest(this.eventId, form);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("removeGuest", this.guestToDelete);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    closeSearch() {
      this.guests = null;
    }
  },
};
</script>