<template>
  <div class="absolute inset-0">
    <nav
      class="fixed top-0 flex w-full py-4 items-center justify-between z-30 bg-white px-8 sm:px-16 smooth"
      :class="[
        { 'bg-opacity-100 shadow-primary': showNavBarBackground },
        { 'bg-opacity-0': !showNavBarBackground },
      ]"
    >
      <div class="w-16 sm:w-20">
        <daisi-icon class="w-full" />
      </div>

      <div class="flex justify-end">
        <router-link
          :to="{ name: 'login' }"
          class="flex flex-shrink-0 w-fit justify-center items-center text-sm h-8 px-4 font-semibold rounded-lg button-interactive border-2 border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white"
        >
          Iniciar Sesión
        </router-link>
      </div>
    </nav>

    <section
      class="bg-gradient-to-b from-background-700 to-background-600 pt-24 pb-16 px-4 md:px-8"
    >
      <h1
        class="h1 text-center w-full sm:w-3/4 mx-auto text-primary-500"
        v-scrollanimation="'enter-from-header'"
      >
        Crea tu invitación de boda inteligente en instantes...
      </h1>
      <div v-scrollanimation="'enter-from-bottom-faded'">
        <a
          class="primary-btn w-fit mt-12 mx-auto"
          :href="getDemoUrl()"
          target="_blank"
          >Ver Demo</a
        >
      </div>
    </section>

    <section
      class="bg-gradient-to-b from-background-600 to-background-500 py-16 px-4 md:px-8 justify-center"
    >
      <div class="flex flex-wrap justify-evenly items-center">
        <div
          class="flex flex-col w-full lg:w-1/3"
          v-scrollanimation="'enter-from-left'"
        >
          <h2 class="h2">Olvídate de esperar</h2>

          <p class="sm:text-lg text-justify mt-4">
            Con <span class="text-primary-500">Daisi</span>, tienes el
            control total. A través de nuestro sistema de gestión de contenido
            (CMS), tú puedes crear, editar y enviar tu invitación de boda en
            instantes.
          </p>

          <ul class="mt-4">
            <li>1. Crea tu cuenta</li>
            <li>2. Elige tu paquete</li>
            <li>3. Edita tu invitación</li>
            <li>4. ¡Envíala a tus invitados!</li>
          </ul>

          <router-link
            :to="{ name: 'login' }"
            class="primary-btn w-fit mt-4 mx-auto"
            >Comenzar</router-link
          >
        </div>

        <div
          class="w-full lg:w-1/2 mt-4 lg:mt-0 rounded-lg border-8 border-content-500 shadow-primary"
          v-scrollanimation="'enter-from-right'"
        >
          <img
            v-lazy="getAdminScreen()"
            class="w-full h-full mx-auto"
            alt="Pantalla de Daisi CMS"
            title="admin"
          />
        </div>
      </div>
    </section>

    <section class="bg-background-500 py-16 px-4 md:px-8">
      <div
        class="shadow-primary rounded-lg p-4 h-fit w-full lg:w-2/3 mt-4 mx-auto"
        v-scrollanimation
      >
        <p class="sm:text-lg text-center">
          Puedes activar tu invitación en modo prueba, ¡esto es totalmente
          gratis! No necesitas agregar ninguna tarjeta y podrás editar y
          visualizar tu invitación por un periodo de 12 horas.
        </p>
        <router-link
          :to="{ name: 'login' }"
          class="primary-btn mt-4 w-fit mx-auto"
          >Comenzar Prueba</router-link
        >
      </div>
    </section>

    <section class="bg-background-500 py-16 px-4 md:px-8">
      <h2 class="h2 mx-auto" v-scrollanimation>Paquetes</h2>
      <div class="flex flex-wrap justify-evenly mt-4">
        <div
          v-for="plan in plans"
          :key="plan.id"
          class="flex flex-col shadow-primary p-4 w-full sm:w-80 relative rounded-lg text-center mt-4 sm:mx-4 pb-24"
          v-scrollanimation
        >
          <h3 class="h3 mx-auto font-bold text-2xl">{{ plan.plan }}</h3>
          <div class="flex items-baseline mx-auto">
            <h2 class="h2 my-4 text-secondary-500 font-semibold text-lg mr-1">
              {{ integerToCurrency(plan.price) }}
            </h2>
            <p class="text-secondary-500 font-bold">MXN</p>
          </div>
          <p class="text-content-300 mb-4 italic">"{{ plan.description }}"</p>
          <p
            v-for="feature in plan.features"
            :key="feature.id"
            class="mt-2 font-medium text-content-500"
          >
            - {{ feature.feature }}
          </p>

          <router-link
            :to="{ name: 'login' }"
            class="absolute bottom-4 left-4 right-4 flex flex-shrink-0 justify-center items-center text-base h-14 px-6 font-semibold rounded-lg button-interactive border-2 border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white"
          >
            Comprar
          </router-link>
        </div>
      </div>
    </section>

    <section class="bg-background-500 w-full flex flex-col pb-16 px-4 md:px-8">
      <div
        class="flex flex-col md:flex-row justify-evenly w-full mx-auto text-sm md:text-base"
        v-scrollanimation
      >
        <div class="flex flex-col text-center md:text-left">
          <div class="font-bold">Contáctanos</div>
          <div class="text-opacity-80 mt-2">
            <phone-icon class="w-3 fill-current inline-block mr-2" />8128728916
          </div>
          <div class="text-opacity-80">
            <mail-icon
              class="w-3 fill-current inline-block mr-2"
            />contacto@daisi.mx
          </div>
        </div>

        <div class="mt-4 md:mt-0 text-center md:text-left">
          <div class="font-bold">Recursos</div>

          <router-link
            :to="{ name: 'terms' }"
            class="button-interactive underline mt-2"
          >
            Aviso de privacidad
          </router-link>

          <br />

          <router-link
            :to="{ name: 'terms' }"
            class="button-interactive underline mt-3"
          >
            Términos y Condiciones
          </router-link>
        </div>
      </div>

      <div
        class="flex mx-auto fill-current mt-6 items-center"
        v-scrollanimation
      >
        <visa-icon class="w-10" />
        <mastercard-icon class="w-10 ml-4" />
        <amex-icon class="w-10 ml-4" />
      </div>

      <div class="font-bold mx-auto mt-6" v-scrollanimation>Daisi</div>
    </section>

    <contact />
  </div>
</template>

<script>
import api from "../api";
import CloseIcon from "../icons/CloseIcon";
import VisaIcon from "../icons/VisaIcon";
import MastercardIcon from "../icons/MastercardIcon";
import AmexIcon from "../icons/AmexIcon";
import PhoneIcon from "../icons/PhoneIcon";
import MailIcon from "../icons/MailIcon";
import Contact from "../components/ui/Contact";
import { useHead } from '@unhead/vue'
export default {
  name: "Landing",
  
  setup() {
    useHead({
      titleTemplate: '%s | Tu invitación de boda inteligente'
    })
  },

  components: {
    CloseIcon,
    VisaIcon,
    MastercardIcon,
    AmexIcon,
    PhoneIcon,
    MailIcon,
    Contact,
  },

  data() {
    return {
      plans: null,
      loadingPlans: false,
      showNavBarBackground: false,
    };
  },

  created() {
    this.loadPlans();
  },

  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.onScroll);
    });
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    getDemoUrl() {
      return process.env.VUE_APP_DEMO_URL;
    },

    getAdminScreen() {
      return process.env.VUE_APP_ADMIN_SCREEN;
    },

    onScroll() {
      if (window.scrollY > 0) {
        if (!this.showNavBarBackground) {
          this.showNavBarBackground = true;
        }
      } else {
        this.showNavBarBackground = false;
      }
    },

    async loadPlans() {
      this.loadingPlans = true;

      try {
        this.plans = await api.invitationPlans.getAll();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingPlans = false;
    },

    integerToCurrency(amount) {
      const formatter = new Intl.NumberFormat({
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
      });

      return "$" + formatter.format(amount);
    },

    startTypingAnimation() {
      new Typed("#typed", {
        stringsElement: "#typed-strings",
        typeSpeed: 40,
        showCursor: false,
      });
    },
  },
};
</script>

<style scoped>
.h1 {
  @apply text-5xl sm:text-6xl md:text-7xl font-bold;
}
.h2 {
  @apply text-4xl sm:text-5xl;
}
.h3 {
  @apply text-3xl;
}
section {
  @apply flex flex-col;
}
.before-enter {
  opacity: 0;
  transition: all 2s ease-out;
}
.enter {
  opacity: 1;
}
.enter-from-header {
  animation: enterFromLeft 2s cubic-bezier(0.42, -0.01, 0.51, 1.37) forwards;
}
.enter-from-bottom-faded {
  animation: enterFromBottomFaded 2s cubic-bezier(0.42, -0.01, 0.51, 1.37)
    forwards;
}
.enter-from-top {
  animation: enterFromTop 2s ease forwards;
}
.enter-from-left {
  animation: enterFromLeft 2s ease forwards;
}
.enter-from-right {
  animation: enterFromRight 2s ease forwards;
}
.enter-from-bottom {
  animation: enterFromBottom 2s ease forwards;
}
@keyframes enterFromTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes enterFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes enterFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes enterFromBottom {
  from {
    transform: translateY(100%);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes enterFromBottomFaded {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>