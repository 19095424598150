<template>
  <div class="flex flex-col justify-center items-center absolute inset-0">
    <form class="auth-container">
      <div class="title">Crea tu cuenta</div>

      <div class="flex flex-col w-full mt-6">
        <custom-label for="email" value="Correo electrónico"></custom-label>
        <custom-input
          id="email"
          type="email"
          v-model="form.email"
        ></custom-input>
        <validation-errors :errors="errorFor('email')"></validation-errors>
      </div>

      <div class="flex flex-col w-full mt-4">
        <div class="flex justify-between">
          <custom-label for="password" value="Contraseña"></custom-label>
          <button class="link" @click.prevent="showPassword = !showPassword">
            {{ passwordButtonText }}
          </button>
        </div>

        <custom-input
          id="password"
          :type="passwordType"
          v-model="form.password"
        ></custom-input>

        <validation-errors :errors="errorFor('password')"></validation-errors>

        <password-validation
          class="mt-1"
          :password="form.password"
        ></password-validation>
      </div>

      <div class="flex flex-col w-full mt-4">
        <div class="flex justify-between">
          <custom-label
            for="passwordConfirmation"
            value="Confirmar contraseña"
          ></custom-label>
          <button
            class="link"
            @click.prevent="showConfirmPassword = !showConfirmPassword"
          >
            {{ confirmPasswordButtonText }}
          </button>
        </div>

        <custom-input
          id="passwordConfirmation"
          :type="confirmPasswordType"
          v-model="form.passwordConfirmation"
        ></custom-input>

        <password-validation
          class="mt-1"
          :password="form.password"
          :passwordConfirmation="form.passwordConfirmation"
          :confirmation="true"
        ></password-validation>

        <validation-errors
          :errors="errorFor('passwordConfirmation')"
        ></validation-errors>
      </div>

      <div class="flex flex-col w-full mt-6">
        <custom-label for="name" value="Nombre(s)"></custom-label>
        <custom-input id="name" type="text" v-model="form.name"></custom-input>
        <validation-errors :errors="errorFor('name')"></validation-errors>
      </div>

      <div class="flex flex-col w-full mt-6">
        <custom-label for="lastName" value="Apellido(s)"></custom-label>
        <custom-input
          id="lastName"
          type="text"
          v-model="form.lastName"
        ></custom-input>
        <validation-errors :errors="errorFor('lastName')"></validation-errors>
      </div>

      <div class="flex items-center mt-10">
        <input
          id="checkbox"
          type="checkbox"
          class="h-5 w-5"
          v-model="acceptsTerms"
        />
        <span for="checkbox" class="ml-2"
          >He leído y acepto los Términos y Condiciones</span
        >
      </div>
      <validation-errors :errors="errorFor('acceptsTerms')"></validation-errors>

      <a
        class="mt-2 mx-auto link underline text-xs"
        :href="getTermsUrl()"
        target="_blank"
        >Aviso de Privacidad y Términos y Condiciones</a
      >

      <primary-button
        class="w-full mt-10"
        :loading="loading"
        @buttonClicked="register"
        >Crear Cuenta
      </primary-button>

      <google-button
        class="w-full mt-4"
        :loading="this.loading"
        @buttonClicked="googleSignIn"
      >
        Acceder con Google
      </google-button>

      <div class="mt-6 text-center">
        ¿Ya tienes cuenta?
        <router-link :to="{ name: 'login' }" class="link"
          >Inicia sesión</router-link
        >
      </div>
    </form>
  </div>
</template>

<script>
import api from "../../api";
import PasswordValidation from "../../components/ui/PasswordValidation";
import GoogleButton from "../../components/ui/GoogleButton";
import validationErrors from "../../mixins/validationErrors";
import { is422 } from "../../utils/response";
import { mapState } from "vuex";
import { useHead } from '@unhead/vue'
export default {
  name: "Register",

  setup() {
    useHead({
      titleTemplate: '%s | Registro'
    })
  },

  components: {
    PasswordValidation,
    GoogleButton,
  },

  data() {
    return {
      form: {
        email: "",
        password: "",
        passwordConfirmation: "",
        name: "",
        lastName: "",
      },
      loading: false,
      showPassword: false,
      showConfirmPassword: false,
      acceptsTerms: false,
    };
  },

  mixins: [validationErrors],

  computed: {
    passwordButtonText() {
      return this.showPassword ? "Ocultar" : "Mostrar";
    },

    passwordType() {
      return this.showPassword ? "text" : "password";
    },

    confirmPasswordButtonText() {
      return this.showConfirmPassword ? "Ocultar" : "Mostrar";
    },

    confirmPasswordType() {
      return this.showConfirmPassword ? "text" : "password";
    },
  },

  methods: {
    validateForm() {
      this.errors = {};

      this.validateEmail(this.form.email);
      this.validateRegisterPassword(this.form.password);
      this.validatePasswordConfirmation(
        this.form.password,
        this.form.passwordConfirmation
      );
      this.validateName(this.form.name);
      this.validateLastName(this.form.lastName);

      if (!this.acceptsTerms) {
        this.errors["acceptsTerms"] = [
          "Debes aceptar los Términos y Condiciones.",
        ];
      }

      return Object.keys(this.errors).length === 0;
    },

    async register() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      try {
        const response = await api.auth.register(this.form);
        this.$store.dispatch("login", response);
        this.$store.dispatch("successSnack", "Cliente creado exitosamente");
        this.$router.push({ name: "unverified" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
    },

    async googleSignIn() {
      try {
        const response = await api.auth.getGoogleAuthRedirect();
        window.location.href = response.url;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    getTermsUrl() {
      const route = this.$router.resolve({ name: 'terms' });
      return route.href;
    }
  },
};
</script>

<style scoped>
input[type="checkbox"] {
  accent-color: #316cf6;
}
</style>