<template>
  <div class="flex flex-col justify-center items-center absolute inset-0">
    <form class="auth-container">
      <div class="title mx-auto text-center">Restablece tu contraseña</div>

      <div class="flex flex-col w-full mt-4">
        <div class="flex justify-between">
          <custom-label for="password" value="Contraseña"></custom-label>
          <button class="link" @click.prevent="showPassword = !showPassword">
            {{ passwordButtonText }}
          </button>
        </div>

        <custom-input
          id="password"
          :type="passwordType"
          v-model="form.password"
        ></custom-input>

        <validation-errors :errors="errorFor('password')"></validation-errors>

        <password-validation
          class="mt-1"
          :password="form.password"
        ></password-validation>
      </div>

      <div class="flex flex-col w-full mt-4">
        <div class="flex justify-between">
          <custom-label
            for="passwordConfirmation"
            value="Confirmar contraseña"
          ></custom-label>
          <button
            class="link"
            @click.prevent="showConfirmPassword = !showConfirmPassword"
          >
            {{ confirmPasswordButtonText }}
          </button>
        </div>

        <custom-input
          id="passwordConfirmation"
          :type="confirmPasswordType"
          v-model="passwordConfirmation"
        ></custom-input>

        <password-validation
          class="mt-1"
          :password="form.password"
          :passwordConfirmation="passwordConfirmation"
          :confirmation="true"
        ></password-validation>

        <validation-errors
          :errors="errorFor('passwordConfirmation')"
        ></validation-errors>
      </div>

      <primary-button
        class="w-full mt-4"
        :loading="loading"
        @buttonClicked="resetPassword"
        >Restablecer
      </primary-button>

      <div class="mt-6 text-center">
        <router-link :to="{ name: 'login' }" class="link"
          >Inicia sesión</router-link
        >
      </div>
    </form>
  </div>
</template>

<script>
import api from "../../api";
import PasswordValidation from "../../components/ui/PasswordValidation";
import validationErrors from "../../mixins/validationErrors";
import { is422 } from "../../utils/response";
export default {
  name: "Reset Password",

  components: {
    PasswordValidation,
  },

  data() {
    return {
      form: {
        token: "",
        password: "",
      },
      passwordConfirmation: "",
      loading: false,
      showPassword: false,
      showConfirmPassword: false,
      passwordConfirmation: "",
    };
  },

  mixins: [validationErrors],

  computed: {
    passwordButtonText() {
      return this.showPassword ? "Ocultar" : "Mostrar";
    },

    passwordType() {
      return this.showPassword ? "text" : "password";
    },

    confirmPasswordButtonText() {
      return this.showConfirmPassword ? "Ocultar" : "Mostrar";
    },

    confirmPasswordType() {
      return this.showConfirmPassword ? "text" : "password";
    },
  },

  async created() {
    this.form.token = this.$route.params.token;
  },

  methods: {
    validateForm() {
      this.errors = {};

      this.validateRegisterPassword(this.form.password);
      this.validatePasswordConfirmation(
        this.form.password,
        this.passwordConfirmation
      );

      return Object.keys(this.errors).length === 0;
    },

    async resetPassword() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      try {
        const response = await api.auth.resetPassword(this.form);
        this.$store.dispatch("login", response);
        this.$store.dispatch("successSnack", response.message);
        if (response.client.weddings.length > 0) {
          this.$router.push({
            name: "wedding",
            params: { id: response.client.weddings[0].id },
          });
        } else {
          this.$router.push({ name: "wedding_form" });
        }
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
    },
  },
};
</script>