<template>
  <div v-if="!loading" class="p-4 sm:p-8 flex flex-col">
    <transition name="modal">
      <confirmation-modal
        v-if="showConfirmationModal"
        message="Tu invitación se activará por un periodo de 12 horas. ¡Podrás configurarla con tu información y visualizarla!"
        @close="closeConfirmationModal"
        @confirm="startTrial"
      ></confirmation-modal>
    </transition>

    <transition name="modal">
      <checkout-modal
        v-if="showCheckoutModal"
        :plan="selectedPlan"
        :currentProductId="
          wedding.invitationPlan ? wedding.invitationPlan.productId : null
        "
        @close="showCheckoutModal = false"
        @loadWedding="loadWedding"
        @loadUpgradeOptions="loadUpgradeOptions"
      ></checkout-modal>
    </transition>

    <div v-if="wedding.invitationPlan">
      <section class="section flex flex-col text-center">
        <h2>
          ¡Tu paquete
          <span class="text-secondary-500">{{
            wedding.invitationPlan.name
          }}</span>
          se encuentra activo!
        </h2>

        <p class="text-content-400 text-semibold mt-2">
          Edita tu invitación y envíala a tus invitados.
        </p>
        <a class="primary-btn mx-auto mt-4" :href="wedding.link" target="_blank"
          >Tu Invitación</a
        >
      </section>

      <section
        v-if="upgradeOptions && upgradeOptions.length > 0"
        class="section mt-8 flex flex-col"
      >
        <p class="text-content-500 text-center mt-2">
          <span class="text-primary-500 font-bold">Mejora tu paquete</span> para
          desbloquear más funciones y hacer tu boda aún más especial. Con un
          paquete superior, tendrás acceso a más opciones de personalización,
          más imágenes en tu galería, y la posibilidad de administrar a tus
          invitados de manera más eficiente. ¡Eleva la experiencia de tu boda al
          siguiente nivel!
        </p>
      </section>

      <div v-if="!loadingUpgradeOptions" class="flex flex-col">
        <div
          v-if="upgradeOptions && upgradeOptions.length > 0"
          class="flex flex-wrap justify-evenly mt-8"
        >
          <div
            v-for="plan in upgradeOptions"
            :key="plan.id"
            class="flex flex-col shadow-primary bg-white p-4 w-full sm:w-80 relative pb-24 rounded-lg text-center mt-4 sm:mx-4"
          >
            <p class="mx-auto font-bold text-2xl">{{ plan.plan }}</p>
            <div class="flex items-baseline mx-auto">
              <h1 class="my-4 text-secondary-500 mr-1">
                {{ integerToCurrency(plan.price) }}
              </h1>
              <p class="text-secondary-500 font-bold">MXN</p>
            </div>
            <p class="text-sm text-content-300 mb-4 italic">
              "{{ plan.description }}"
            </p>
            <p
              v-for="feature in plan.features"
              :key="feature.id"
              class="mt-2 font-medium text-content-500"
            >
              - {{ feature.feature }}
            </p>

            <button
              class="absolute bottom-4 left-4 right-4 buy-btn"
              @click="openCheckout(plan)"
            >
              Mejorar
            </button>
          </div>
        </div>
      </div>

      <div
        v-else-if="loadingUpgradeOptions"
        class="flex w-full h-full items-center"
      >
        <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
      </div>

      <section v-if="wedding.payments" class="section mt-8">
        <h2>Pagos</h2>

        <div class="flex flex-wrap">
          <div
            v-for="payment in wedding.payments"
            :key="payment.id"
            class="p-4 shadow-primary rounded-lg mt-6 w-full sm:w-fit sm:mr-4"
          >
            <p class="font-semibold text-secondary-500">
              {{ integerToCurrency(payment.amount / 100) }}MXN
            </p>
            <p class="text-sm text-content-400 text-semibold">
              {{ getFormattedDate(payment.createdAt) }}
            </p>
          </div>
        </div>
      </section>
    </div>

    <div v-else class="flex flex-col" :class="[{ 'h-full': !plans }]">
      <section
        v-if="wedding.trialEndsAt"
        class="section flex flex-col text-center"
      >
        <div v-if="trialIsActive" class="mx-auto flex flex-col">
          <p class="mx-auto font-semibold">Tu periodo de prueba termina en</p>
          <div v-if="!isLoadingCountdown" class="flex mx-auto text-primary-500">
            <h1>{{ this.countdown.hours }}:</h1>
            <h1>{{ this.countdown.minutes }}:</h1>
            <h1>{{ this.countdown.seconds }}</h1>
          </div>
          <a
            class="primary-btn mx-auto mt-4"
            :href="wedding.link"
            target="_blank"
            >Tu Invitación</a
          >
        </div>
        <p v-else class="mx-auto font-semibold">
          Tu periodo de prueba ha terminado.
        </p>
      </section>

      <div v-if="plans" class="flex flex-col h-full">
        <button
          v-if="!wedding.trialEndsAt"
          class="link mb-8 mx-auto underline"
          @click="openConfirmationModal"
        >
          Activar Invitación en modo prueba
        </button>

        <div class="flex flex-wrap justify-evenly">
          <div
            v-for="plan in plans"
            :key="plan.id"
            class="flex flex-col shadow-primary bg-white p-4 w-full sm:w-80 relative pb-24 rounded-lg text-center mt-4 sm:mx-4"
          >
            <p class="mx-auto font-bold text-2xl">{{ plan.plan }}</p>
            <div class="flex items-baseline mx-auto">
              <h1 class="my-4 text-secondary-500 mr-1">
                {{ integerToCurrency(plan.price) }}
              </h1>
              <p class="text-secondary-500 font-bold">MXN</p>
            </div>
            <p class="text-sm text-content-300 mb-4 italic">
              "{{ plan.description }}"
            </p>
            <p
              v-for="feature in plan.features"
              :key="feature.id"
              class="mt-2 font-medium text-content-500"
            >
              - {{ feature.feature }}
            </p>

            <button
              class="absolute bottom-4 left-4 right-4 buy-btn"
              @click="openCheckout(plan)"
            >
              Comprar
            </button>
          </div>
        </div>
      </div>

      <div v-else class="flex w-full h-full items-center">
        <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
      </div>
    </div>
  </div>
  <div v-else class="flex w-full h-full items-center">
    <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
  </div>
</template>

<script>
import api from "../../../api";
import CheckoutModal from "../../../components/ui/CheckoutModal";
import confirmationMixin from "../../../mixins/confirmationMixin";
import { mapState } from "vuex";
export default {
  name: "Activation",

  components: {
    CheckoutModal,
  },

  data() {
    return {
      plans: null,
      upgradeOptions: null,
      loadingPlans: false,
      loadingUpgradeOptions: false,
      countdown: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      intervalId: null,
      isLoadingCountdown: true,
      showCheckoutModal: false,
      selectedPlan: null,
      loading: false,
    };
  },

  computed: {
    ...mapState({
      wedding: "wedding",
      collapsed: "collapsed",
      showLoginModal: "showLoginModal",
    }),

    trialIsActive() {
      if (!this.wedding && !this.wedding.trialEndDate) {
        return false;
      }

      if (this.wedding.invitationPlanId) {
        return false;
      }

      return this.isTrialActive(this.wedding.trialEndsAt);
    },
  },

  mixins: [confirmationMixin],

  created() {
    this.$store.commit("setSelectedTab", "activation");
    this.$store.commit("setActivateInvitation", false);
    if (!this.wedding.invitationPlanId) {
      this.loadPlans();
    } else {
      this.loadUpgradeOptions();
    }
    if (this.trialIsActive) {
      this.startCountdown();
    }
  },

  beforeRouteLeave() {
    this.$store.commit("setActivateInvitation", true);
  },

  methods: {
    async loadPlans() {
      this.loadingPlans = true;

      try {
        this.plans = await api.invitationPlans.getAll();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingPlans = false;
    },

    async loadUpgradeOptions() {
      this.loadingUpgradeOptions = true;

      try {
        this.upgradeOptions = await api.invitationPlans.getUpgradeOptions({
          weddingId: this.wedding.id,
        });
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingUpgradeOptions = false;
    },

    integerToCurrency(amount) {
      const formatter = new Intl.NumberFormat({
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
      });

      return "$" + formatter.format(amount);
    },

    async startTrial() {
      this.closeConfirmationModal();

      try {
        const response = await api.weddings.startTrial(this.$route.params.id);
        this.$store.dispatch("successSnack", response.message);

        const updatedWedding = JSON.parse(JSON.stringify(this.wedding));
        updatedWedding.active = true;
        updatedWedding.trialEndsAt = response.trialEndsAt;

        this.$store.commit("setWedding", updatedWedding);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    isTrialActive(trialEndsAt) {
      const trialEndDate = new Date(trialEndsAt);
      const currentDate = new Date();

      return currentDate <= trialEndDate;
    },

    startCountdown() {
      this.intervalId = setInterval(this.calculateCountdown, 1000);
    },

    calculateCountdown() {
      const trialEndsAtDate = new Date(this.wedding.trialEndsAt);

      const now = new Date();

      const distance = trialEndsAtDate - now;

      if (distance < 0) {
        this.loadWedding();
        clearInterval(this.intervalId);
        return;
      }

      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      minutes = minutes.toString().padStart(2, "0");
      seconds = seconds.toString().padStart(2, "0");

      this.countdown = { hours, minutes, seconds };

      this.isLoadingCountdown = false;
    },

    async loadWedding() {
      this.loading = true;

      try {
        const response = await api.weddings.getById(this.$route.params.id);
        this.$store.commit("setWedding", response);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    openCheckout(plan) {
      this.showCheckoutModal = true;

      this.selectedPlan = {
        id: plan.id,
        name: plan.plan,
        priceId: plan.priceId,
        amount: plan.price,
      };
    },

    getFormattedDate(date) {
      const formattedDate = new Date(date);
      return formattedDate.toLocaleString("es-ES", { hour12: true });
    },
  },

  watch: {
    wedding: function () {
      if (this.trialIsActive) {
        this.startCountdown();
      }
    },

    showLoginModal: function (val) {
      if (val === false) {
        if (!this.wedding.invitationPlanId) {
          this.loadPlans();
        } else {
          this.loadUpgradeOptions();
        }
      }
    },
  },
};
</script>

<style scoped>
.buy-btn {
  @apply flex flex-shrink-0 justify-center items-center text-base shadow-primary h-14 px-6 font-semibold rounded-lg button-interactive border-2 border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white;
}
</style>