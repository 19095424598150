<template>
  <input
    :id="id"
    :type="type"
    :placeholder="placeholder"
    :value="modelValue"
    @input="updateValue"
    ref="input"
  />
</template>

<script>
export default {
  props: ["id", "type", "modelValue", "placeholder"],

  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>
