<template>
  <div class="flex flex-col h-screen justify-center items-center inset-0">
    <form class="auth-container relative">
      <div class="w-64 mx-auto">
        <logo-icon class="w-full fill-current text-primary-500" />
      </div>

      <div class="title mx-auto">Accede a tu cuenta</div>

      <div class="flex flex-col w-full mt-6">
        <custom-label for="email" value="Correo electrónico"></custom-label>
        <custom-input
          id="email"
          type="text"
          v-model="form.email"
        ></custom-input>
        <validation-errors :errors="errorFor('email')"></validation-errors>
      </div>

      <div class="flex flex-col w-full mt-4">
        <custom-label for="password" value="Contraseña"></custom-label>

        <custom-input
          id="password"
          type="password"
          v-model="form.password"
        ></custom-input>
        <validation-errors :errors="errorFor('password')"></validation-errors>
      </div>

      <primary-button
        class="w-full mt-4"
        :loading="loading"
        @buttonClicked="login"
        >Iniciar Sesión
      </primary-button>
    </form>
  </div>
</template>

<script>
import api from "../../api";
import validationErrors from "../../mixins/validationErrors";
import { is422 } from "../../utils/response";
import { mapState } from "vuex";

export default {
  name: "AdminLogin",

  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      loading: false,
    };
  },

  mixins: [validationErrors],

  computed: {
    ...mapState({}),
  },

  methods: {
    validateForm() {
      this.errors = {};

      this.validateEmail(this.form.email);
      this.validateLoginPassword(this.form.password);

      return Object.keys(this.errors).length === 0;
    },

    async login() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      try {
        const response = await api.auth.adminLogin(this.form);
        this.$store.dispatch("login", response);
        if (response.client.weddings.length > 0) {
          this.$router.push({
            name: "wedding",
            params: { id: response.client.weddings[0].id },
          });
        } else {
          this.$router.push({ name: "wedding_form" });
        }
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
    },
  },
};
</script>

