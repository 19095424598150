import MessageModal from "../components/ui/MessageModal";
export default {
  components: {
    MessageModal
  },

  data() {
    return {
      showMessageModal: false,
    }
  },

  methods: {
    openMessageModal() {
      this.showMessageModal = true;
    },

    closeMessageModal() {
      this.showMessageModal = false;
    }
  }
}