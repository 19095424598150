<template>
  <div
    v-if="client"
    class="flex flex-col justify-center items-center fixed z-50 inset-0 bg-opacity-25 bg-black"
  >
    <form class="modal relative">
      <div class="title mx-auto">Autenticación</div>

      <div v-if="client.externalAuth === 'google'">
        <p class="mt-6 text-center text-sm">
          Autentica tu cuenta con Google, se abrirá otra ventana pero después puedes actualizar y continuar en esta ventana para no perder tus cambios.
        </p>

        <a
          class="w-full mt-4 primary-btn"
          :loading="loading"
          :href="googleAuthUrl"
          target="_blank"
        >
          <img
            class="w-6 h-6 mr-2"
            :src="require('@/assets/google.png')"
            alt=""
          />
          Acceder con Google
        </a>

        <primary-button
          class="mt-6 w-full"
          :loading="loadingReload"
          @buttonClicked="loadMe"
          >Actualizar</primary-button
        >
      </div>

      <div v-else>
        <div class="flex flex-col w-full mt-6">
          <custom-label for="email" value="Correo electrónico"></custom-label>
          <custom-input
            class="w-full"
            id="email"
            type="email"
            v-model="form.email"
            disabled
          ></custom-input>
          <validation-errors :errors="errorFor('email')"></validation-errors>
        </div>

        <div class="flex flex-col w-full mt-4">
          <custom-label for="password" value="Contraseña"></custom-label>

          <custom-input
            class="w-full"
            id="password"
            type="password"
            v-model="form.password"
          ></custom-input>
          <validation-errors :errors="errorFor('password')"></validation-errors>
        </div>

        <primary-button
          class="w-full mt-4"
          :loading="loading"
          @buttonClicked="login"
          >Iniciar Sesión
        </primary-button>
      </div>
    </form>
  </div>
</template>

<script>
import api from "../../api";
import GoogleButton from "../../components/ui/GoogleButton";
import validationErrors from "../../mixins/validationErrors";
import { is422 } from "../../utils/response";
import { mapState } from "vuex";
export default {
  name: "Login Modal",

  components: {
    GoogleButton,
  },

  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      loading: false,
      googleAuthUrl: null,
      loadingReload: false,
    };
  },

  mixins: [validationErrors],

  computed: {
    ...mapState({
      client: "client",
    }),
  },

  mounted() {
    if (this.client) {
      this.form.email = this.client.email;
    }

    this.getGoogleAuthUrl();
  },

  methods: {
    validateForm() {
      this.errors = {};

      this.validateEmail(this.form.email);
      this.validateLoginPassword(this.form.password);

      return Object.keys(this.errors).length === 0;
    },

    async login() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      try {
        const response = await api.auth.login(this.form);
        this.$store.dispatch("login", response);
        this.$store.commit("setShowLoginModal", false);
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
    },

    async getGoogleAuthUrl() {
      this.loading = true;

      try {
        const response = await api.auth.getGoogleAuthRedirect();
        this.googleAuthUrl = response.url;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async loadMe() {
      this.loadingReload = true;

      try {
        const response = await api.clients.me();
        if (this.client.email === response.email) {
          this.$store.commit("setShowLoginModal", false);
        } else {
          this.$store.dispatch("logout");
        }
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingReload = false;
    },
  },
};
</script>