<template>
  <div class="flex flex-col absolute inset-0">
    <router-view class="bg-background-500" />

    <transition name="modal">
      <login-modal v-if="showLoginModal"></login-modal>
    </transition>

    <transition name="snack">
      <snack v-if="snack" :snack="snack" />
    </transition>
  </div>
</template>

<script>
import LoginModal from "./components/ui/LoginModal";
import Snack from "./components/ui/Snack";
import { mapState } from "vuex";
import { useHead } from "@unhead/vue";
export default {
  name: "App",

  setup() {
    useHead({
      title: "Daisi",
      meta: [
        {
          name: "description",
          content:
            "Daisi te ayuda a crear, editar y enviar tu invitación de boda en instantes. ¡Olvídate de esperar!",
        },
        {
          property: "og:title",
          content: "Daisi | Tu invitación de boda inteligente",
        },
        {
          property: "og:description",
          content:
            "Daisi te ayuda a crear, editar y enviar tu invitación de boda en instantes. ¡Olvídate de esperar!",
        },
        {
          property: "og:url",
          content: "https://daisi.mx",
        },
        {
          property: "og:image",
          content: "https://daisi.mx/daisi_logo.png",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
      ],
    });
  },

  components: {
    LoginModal,
    Snack,
  },

  computed: {
    ...mapState({
      showLoginModal: "showLoginModal",
      snack: "snack",
    }),
  },
};
</script>

<style >
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
.snack-enter-active,
.snack-leave-active {
  transition: all 0.3s ease;
}
.snack-enter-from,
.snack-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
</style>