let handleOutsideClick;

export default {
  beforeMount(el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation();

      const handler = binding.value;

      let clickedOnExcludedEl = false;

      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        handler();
      }
    };
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  },
  unmounted() {
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
  },
}
