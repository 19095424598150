export default {
  path: '/guests',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async search(form) {
    try {
      const response = await this.apiClient.post(`${this.path}/search`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getByWedding(form) {
    try {
      const response = await this.apiClient.post(`${this.path}/get-by-wedding`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getSummary(form) {
    try {
      const response = await this.apiClient.post(`${this.path}/get-summary`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async create(form) {
    try {
      const response = await this.apiClient.post(`${this.path}`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async uploadFile(form) {
    try {
      const response = await this.apiClient.post(`${this.path}/upload-file`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async update(id, form) {
    try {
      const response = await this.apiClient.put(`${this.path}/${id}`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateName(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/name`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateLastName(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/last-name`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateMaxGuestsAllowed(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/max-guests-allowed`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateSent(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/sent`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async delete(id) {
    try {
      const response = await this.apiClient.delete(`${this.path}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async download(form) {
    try {
      const response = await this.apiClient.post(`${this.path}/download`, form, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'guests.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw error;
    }
  },
}