<template>
  <div class="p-4 sm:p-8 flex flex-col">
    <transition name="modal">
      <message-modal
        v-if="showMessageModal"
        :message="messageToShow"
        @close="closeMessageModal"
      ></message-modal>
    </transition>

    <transition name="modal">
      <delete-modal
        v-if="showDeleteModal"
        @close="closeDeleteModal"
        @confirmDelete="deleteGuest"
      ></delete-modal>
    </transition>

    <section
      v-if="validateIfFeatureAvailable('rsvpAdmin') && wedding.active"
      class="section mb-8 flex flex-col"
    >
      <div
        class="w-full p-4 border border-primary-500 rounded-lg flex flex-col"
      >
        <p class="mx-auto font-semibold text-xl mb-2">Invitaciones</p>

        <div v-if="summary" class="flex flex-wrap flex-col xs:flex-row justify-evenly">
          <div class="flex flex-col text-center w-full xs:w-1/3">
            <p class="text-sm xs:text-base sm:text-lg font-bold">{{ summary.totalInvitations }}</p>
            <p class="text-sm xs:text-base sm:text-lg">Totales</p>
          </div>

          <div class="flex flex-col text-center w-full xs:w-1/3">
            <p class="text-sm xs:text-base sm:text-lg font-bold text-secondary-500">
              {{ summary.sentInvitations }}
            </p>
            <p class="text-sm xs:text-base sm:text-lg">Enviadas</p>
          </div>

          <div class="flex flex-col text-center w-full xs:w-1/3">
            <p class="text-sm xs:text-base sm:text-lg font-bold text-primary-500">
              {{ summary.pendingInvitations }}
            </p>
            <p class="text-sm xs:text-base sm:text-lg">Pendientes de enviar</p>
          </div>
        </div>
      </div>

      <div
        class="w-full p-4 border border-primary-500 rounded-lg flex flex-col mt-8"
      >
        <p class="mx-auto font-semibold text-xl mb-2">Invitados</p>

        <div v-if="summary" class="flex flex-wrap flex-col xs:flex-row justify-evenly">
          <div class="flex flex-col text-center w-full xs:w-1/3">
            <p class="text-sm xs:text-base sm:text-lg font-bold">
              {{ summary.totalGuests }}
            </p>
            <p class="text-sm xs:text-base sm:text-lg">Totales</p>
          </div>

          <div class="flex flex-col text-center w-full xs:w-1/3">
            <p class="text-sm xs:text-base sm:text-lg font-bold text-secondary-500">
              {{ summary.totalConfirmedGuests }}
            </p>
            <p class="text-sm xs:text-base sm:text-lg">Confirmados</p>
          </div>

          <div class="flex flex-col text-center w-full xs:w-1/3">
            <p class="text-sm xs:text-base sm:text-lg font-bold text-primary-500">
              {{ summary.totalCanceledGuests }}
            </p>
            <p class="text-sm xs:text-base sm:text-lg">No asistirán</p>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap justify-evenly items-center">
        <router-link :to="{ name: 'guest_form' }" class="secondary-btn mt-8"
          >Agregar Invitado</router-link
        >

        <router-link :to="{ name: 'guest_batch' }" class="secondary-btn mt-8 break-normal h-auto xs:h-8"
          >Agregar Invitados con Archivo</router-link
        >

        <secondary-button @buttonClicked="download" class="mt-8">
          Exportar Excel
        </secondary-button>
      </div>

      <custom-input
        class="mx-auto mt-8 w-full md:w-96 placeholder:text-center text-center text-lg"
        id="guestName"
        type="text"
        placeholder="Buscar por Nombre"
        v-model="searchText"
        @input="search"
      ></custom-input>
    </section>

    <section
      v-else-if="wedding.active && wedding.invitationPlan"
      class="section flex flex-col mb-8"
    >
      <h2 class="mx-auto text-error-500">No incluído en tu plan.</h2>
    </section>

    <section
      v-else-if="wedding.active && !wedding.invitationPlan"
      class="section flex flex-col mb-8"
    >
      <h2 class="mx-auto text-error-500">No incluído en modo de prueba.</h2>
    </section>

    <table
      v-if="guests"
      class="table-auto text-sm bg-white rounded-lg shadow-primary mt-8"
    >
      <thead class="text-left">
        <tr class="text-center">
          <th class="p-2 border-r">Nombre</th>
          <th class="p-2 border-r">Teléfono</th>
          <th class="p-2 border-r"># de invitados</th>
          <th class="p-2 border-r">Respuesta</th>
          <th class="p-2 border-r"># de confirmados</th>
          <th class="p-2 border-r">Restricción de alimentos</th>
          <th class="p-2 border-r">Mensaje</th>
          <th class="p-2 border-r">Liga de invitación</th>
          <th class="p-2 border-r">Enviada</th>
          <!-- <th class="p-2">Código</th> -->
          <th class="p-2">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="guest in guests" :key="guest.id">
          <td class="p-2 border-t border-r">{{ guest.name }}</td>
          <td class="p-2 border-t border-r">{{ guest.phone }}</td>
          <td class="p-2 border-t border-r">{{ guest.maxGuestsAllowed }}</td>
          <td class="p-2 border-t border-r">
            {{
              guest.confirmation === null
                ? ""
                : guest.confirmation
                ? "Sí"
                : "No"
            }}
          </td>
          <td class="p-2 border-t border-r">{{ guest.confirmedGuests }}</td>
          <td class="p-2 border-t border-r">
            <div class="flex justify-center">
              <button
                v-if="guest.foodRestrictions"
                class="link underline flex-shrink-0"
                @click.prevent="
                  messageToShow = guest.foodRestrictions;
                  openMessageModal();
                "
              >
                Ver
              </button>
            </div>
          </td>
          <td class="p-2 border-t border-r">
            <div class="flex justify-center">
              <button
                v-if="guest.message"
                class="link underline flex-shrink-0"
                @click.prevent="
                  messageToShow = guest.message;
                  openMessageModal();
                "
              >
                Ver
              </button>
            </div>
          </td>
          <td class="p-2 border-t border-x">
            <div class="flex items-center justify-evenly relative">
              <a
                class="link underline flex-shrink-0"
                :href="guest.link"
                target="_blank"
                >Abrir</a
              >
              <copy-button
                class="w-5 h-5 button-interactive"
                @buttonClicked="
                  showLinkCopy = guest.id;
                  copyToClipboard(guest.link);
                "
              ></copy-button>
              <transition name="modal">
                <p
                  v-if="showLinkCopy === guest.id"
                  class="p-2 rounded-lg bg-success-500 text-white font-semibold absolute left-0 right-0 -bottom-12 text-center z-50"
                >
                  Copiado
                </p>
              </transition>
            </div>
          </td>
          <td class="p-2 border-t border-r">
            <div class="flex justify-center">
              <custom-toggle
                :modelValue="guest.sent"
                @change="updateSent(guest)"
              ></custom-toggle>
            </div>
          </td>
          <!-- <td class="p-2 border-t font-medium">
            <div class="flex items-center justify-between relative">
              <p class="">{{ guest.code }}</p>
              <copy-button
                class="w-5 h-5 button-interactive"
                @buttonClicked="
                  showCodeCopy = guest.id;
                  copyToClipboard(guest.code);
                "
              ></copy-button>
              <transition name="modal">
                <p
                  v-if="showCodeCopy === guest.id"
                  class="p-2 rounded-lg bg-success-500 text-white font-semibold absolute left-0 right-0 -bottom-12 text-center z-50"
                >
                  Copiado
                </p>
              </transition>
            </div>
          </td> -->
          <td class="p-2 border-t">
            <div class="flex justify-evenly">
              <button
                class="button-interactive"
                @click.prevent="editGuest(guest)"
              >
                <edit-icon class="w-4" />
              </button>
              <button
                class="button-interactive text-error-500 fill-current"
                @click.prevent="
                  guestToDelete = guest.id;
                  openDeleteModal();
                "
              >
                <trashcan-icon class="w-5" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="totalPages > 1 && showPages" class="mt-6 mx-auto">
      <button
        v-for="page in totalPages"
        :key="page"
        class="button-interactive mx-4"
        :class="[
          { 'font-bold': page === selectedPage },
          { 'font-light': page !== selectedPage },
        ]"
        @click.prevent="
          selectedPage = page;
          loadGuests();
        "
      >
        {{ page }}
      </button>
    </div>
  </div>
</template>

<script>
import api from "../../../api";
import CopyButton from "../../../components/ui/CopyButton";
import messageMixin from "../../../mixins/messageMixin";
import deleteMixin from "../../../mixins/deleteMixin";
import { mapState } from "vuex";
export default {
  name: "Guests",

  components: {
    CopyButton,
  },

  data() {
    return {
      guests: null,
      page: null,
      totalPages: null,
      selectedPage: 1,
      loading: false,
      messageToShow: null,
      guestToDelete: null,
      summary: null,
      showCodeCopy: null,
      showLinkCopy: null,
      searchText: null,
      showPages: true,
    };
  },

  computed: {
    ...mapState({
      wedding: "wedding",
      collapsed: "collapsed",
      showLoginModal: "showLoginModal",
    }),
  },

  mixins: [messageMixin, deleteMixin],

  created() {
    this.$store.commit("setSelectedTab", "guests");
    this.loadGuests();
    this.loadSummary();
  },

  methods: {
    async loadGuests() {
      this.loading = true;

      const form = {
        weddingId: this.$route.params.id,
        page: this.selectedPage,
      };

      try {
        const response = await api.guests.getByWedding(form);
        this.guests = response.guests;
        this.page = response.page;
        this.totalPages = response.totalPages;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async loadSummary() {
      const form = {
        weddingId: this.$route.params.id,
      };

      try {
        this.summary = await api.guests.getSummary(form);
      } catch (error) {}
    },

    async deleteGuest() {
      this.closeDeleteModal();

      try {
        const response = await api.guests.delete(this.guestToDelete);
        this.guests = this.guests.filter(
          (guest) => guest.id != this.guestToDelete
        );
        this.$store.dispatch("successSnack", response.message);
        this.loadSummary();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    async download() {
      try {
        await api.guests.download({ weddingId: this.wedding.id });
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    copyToClipboard(text) {
      let textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      setTimeout(() => (this.showCodeCopy = null), 2000);
      setTimeout(() => (this.showLinkCopy = null), 2000);
    },

    editGuest(guest) {
      this.$store.commit("setGuestToEdit", guest);
      this.$router.push({ name: "guest_form" });
    },

    validateIfFeatureAvailable(feature) {
      if (!this.wedding.invitationPlan) {
        return false;
      }

      return this.wedding.invitationPlan.invitationPlanFeatures.some(
        (invitationPlanFeature) => {
          return invitationPlanFeature.slug
            .toLowerCase()
            .includes(feature.toLowerCase());
        }
      );
    },

    async search(event) {
      if (event.target.value == "") {
        this.showPages = true;
        this.loadGuests();
        return;
      }

      this.showPages = false;

      const form = {
        name: event.target.value,
        weddingId: this.wedding.id,
      };

      try {
        this.guests = await api.guests.search(form);
      } catch (error) {}
    },

    async updateSent(guest) {
      try {
        const response = await api.guests.updateSent(guest.id, {
          sent: !guest.sent,
        });
        this.guests.filter((g) => g.id === guest.id)[0].sent = !guest.sent;
        this.$store.dispatch("successSnack", response.message);
        this.loadSummary();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },
  },

  watch: {
    showLoginModal: function (val) {
      if (val === false) {
        this.loadGuests();
        this.loadSummary();
      }
    },
  },
};
</script>