<template>
  <button
    class="w-6 h-6 fill-current button-interactive"
    @click="buttonClicked"
  >
    <close-icon></close-icon>
  </button>
</template>

<script>
import CloseIcon from "../../icons/CloseIcon";
export default {
  components: {
    CloseIcon,
  },

  props: {
    loading: Boolean,
  },

  methods: {
    buttonClicked() {
      this.$emit("buttonClicked");
    },
  },
};
</script>