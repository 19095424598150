export default {
  path: '/clients',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async me() {
    try {
      const response = await this.apiClient.get(this.path + "/me");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateNameAndLastName(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/name-lastname`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
}