<template>
  <div
    class="flex flex-col justify-center items-center fixed z-50 inset-0 bg-opacity-25 bg-black"
  >
    <div class="modal relative">
      <p class="text-center font-semibold">{{ message }}</p>

      <primary-button class="w-full mt-4" @buttonClicked="confirm"
        >Confirmar
      </primary-button>

      <button class="link mt-4 mx-auto w-full" @click.prevent="close">
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirmation Modal',

  props: {
    message: String
  },
  
  methods: {
    close() {
      this.$emit("close");
    },

    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>