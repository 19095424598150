<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M3.8 12.963 2 18l4.8-.63L18.11 6.58a2.612 2.612 0 0 0-3.601-3.785L3.8 12.963z"
    />
  </svg>
</template>