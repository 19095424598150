<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <g fill="#67d449" fill-rule="evenodd">
      <path
        d="M39.826 37.522c-.33.926-1.914 1.77-2.676 1.884-.682.102-1.546.144-2.496-.156a23.092 23.092 0 0 1-2.258-.836c-3.976-1.716-6.574-5.718-6.772-5.982s-1.618-2.148-1.618-4.098 1.024-2.91 1.388-3.306c.362-.396.792-.496 1.056-.496s.528.002.76.014c.244.012.57-.092.892.68.33.794 1.122 2.744 1.222 2.942.098.198.164.43.032.694s-.198.43-.396.662c-.198.23-.416.516-.594.694-.198.196-.406.412-.174.808s1.026 1.694 2.204 2.744c1.514 1.35 2.792 1.768 3.188 1.968.396.198.628.164.858-.1.232-.264.992-1.156 1.256-1.554.264-.396.528-.33.892-.198.362.132 2.312 1.09 2.708 1.29.396.198.66.296.76.462.098.166.098.958-.232 1.884m-7.754-18.844c-7.272 0-13.188 5.912-13.19 13.178 0 2.49.696 4.916 2.016 7.014l.314.498-1.332 4.864 4.99-1.308.48.284a13.145 13.145 0 0 0 6.71 1.838h.006c7.266 0 13.18-5.912 13.184-13.18a13.1 13.1 0 0 0-3.858-9.324 13.097 13.097 0 0 0-9.32-3.864"
      />
      <path
        d="M32.066 47.724h-.006c-2.656 0-5.264-.666-7.58-1.93L16.07 48l2.252-8.218a15.814 15.814 0 0 1-2.118-7.928C16.208 23.112 23.322 16 32.066 16a15.75 15.75 0 0 1 11.22 4.65 15.757 15.757 0 0 1 4.642 11.218c-.004 8.742-7.12 15.856-15.862 15.856M32 0C14.326 0 0 14.326 0 32c0 17.672 14.326 32 32 32 17.672 0 32-14.328 32-32C64 14.326 49.672 0 32 0"
      />
    </g>
  </svg>
</template>