<template>
  <div class="flex items-center">
    <form class="flex flex-col w-full">
      <custom-label :for="field" :value="title"></custom-label>

      <div class="flex items-center">
        <textarea
          class="h-24 mr-4"
          :id="field"
          v-model="form[field]"
          :disabled="!edit"
        ></textarea>

        <button
          v-if="!edit && active"
          class="button-interactive"
          @click.prevent="edit = true"
        >
          <edit-icon class="w-4" />
        </button>

        <div v-else-if="edit" class="flex">
          <button
            class="button-interactive text-error-500 mr-4"
            @click.prevent="edit = false; form[field] = value"
          >
            <close-icon class="w-4 fill-current" />
          </button>

          <button
            type="submit"
            class="button-interactive text-success-500"
            @click.prevent="updateField()"
          >
            <check-icon class="w-8 fill-current" />
          </button>
        </div>
      </div>

      <validation-errors :errors="errorFor(field)"></validation-errors>
    </form>
  </div>
</template>

<script>
import api from "../../api";
import validationErrors from "../../mixins/validationErrors";
import { is422 } from "../../utils/response";
export default {
  name: "WC Text",

  data() {
    return {
      form: {},
      edit: false,
      loading: false,
    };
  },

  props: {
    field: String,
    value: String,
    title: String,
    id: Number,
    resource: Object,
    active: Boolean,
  },

  mixins: [validationErrors],

  created() {
    this.form[this.field] = this.value;
  },

  methods: {
    async updateField() {
      this.loading = true;

      try {
        const response = await api[this.resource.api][this.resource.name](
          this.id,
          this.form
        );
        this.$store.dispatch("successSnack", response.message);
        this.$emit(this.resource.name, this.form[this.field]);
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
      this.edit = false;
    },
  },
};
</script>