<template>
  <div v-if="wedding" class="flex absolute inset-0">
    <router-link
      v-if="!wedding.active && $route.name !== 'activation'"
      :to="{ name: 'activation' }"
      class="bg-secondary-500 text-white font-semibold p-4 rounded-lg shadow-lg w-fit fixed top-8 right-4 sm:right-8 z-50 animate-bounce hidden md:block"
      >¡Activa tu Invitación!</router-link
    >

    <a
      v-else-if="wedding.active && $route.name !== 'activation'"
      class="bg-primary-500 text-white font-semibold p-4 rounded-lg shadow-lg w-fit fixed top-8 right-4 sm:right-8 z-50 animate-bounce hidden md:block"
      :href="wedding.link"
      target="_blank"
      >Tu Invitación</a
    >

    <div
      class="flex flex-col flex-shrink-0 h-full w-16 shadow-xl bg-background-600 py-4 px-2 smooth relative overflow-y-auto"
      :class="[{ 'sm:w-56 sm:py-8': !collapsed }]"
    >
      <div class="mx-auto">
        <daisi-icon :class="[{ 'w-10' : collapsed }, { 'w-10 sm:w-20' : !collapsed }]" />
      </div>

      <p
        v-if="!collapsed"
        class="mx-auto text-center hidden sm:block text-content-400 font-bold text-sm mt-4"
      >
        {{ wedding.name }}
      </p>

      <router-link
        :to="{ name: 'activation' }"
        class="mx-auto mt-6 sm:block link"
        :class="[{ 'text-primary-500': selectedTab === 'activation' }]"
      >
        <div class="flex">
          <activation-icon
            class="w-6 stroke-current"
            :class="[{ 'sm:mr-2': !collapsed }]"
          />
          <span v-if="!collapsed" class="hidden sm:block">Activación</span>
        </div>
      </router-link>

      <router-link
        :to="{ name: 'editing' }"
        class="mx-auto sm:block link mt-6"
        :class="[{ 'text-primary-500': selectedTab === 'editing' }]"
      >
        <div class="flex">
          <paint-icon
            class="w-5 fill-current"
            :class="[{ 'sm:mr-2': !collapsed }]"
          />
          <span v-if="!collapsed" class="hidden sm:block">Edición</span>
        </div>
      </router-link>

      <router-link
        :to="{ name: 'guests' }"
        class="mx-auto mt-6 sm:block link"
        :class="[{ 'text-primary-500': selectedTab === 'guests' }]"
      >
        <div class="flex">
          <guest-icon
            class="w-6 fill-current"
            :class="[{ 'sm:mr-2': !collapsed }]"
          />
          <span v-if="!collapsed" class="hidden sm:block">Invitados</span>
        </div>
      </router-link>

      <router-link
        :to="{ name: 'account' }"
        class="mx-auto mt-6 sm:block link"
        :class="[{ 'text-primary-500': selectedTab === 'account' }]"
      >
        <div class="flex">
          <account-icon
            class="w-6 fill-current"
            :class="[{ 'sm:mr-2': !collapsed }]"
          />
          <span v-if="!collapsed" class="hidden sm:block">Cuenta</span>
        </div>
      </router-link>

      <div class="w-full flex absolute bottom-8 left-0 right-0">
        <button
          class="mx-auto button-interactive w-6 h-6 border-2 border-content-500 p-1 rounded hidden sm:block"
          @click="$store.commit('setCollapsed', !collapsed)"
        >
          <collapse-icon class="w-full fill-current" />
        </button>
      </div>
    </div>
    <router-view class="overflow-auto flex-auto"></router-view>
  </div>
  <div
    v-else
    class="flex w-full h-full items-center"
  >
    <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
  </div>
</template>

<script>
import api from "../../../api";
import CollapseIcon from "../../../icons/CollapseIcon";
import PaintIcon from "../../../icons/PaintIcon";
import GuestIcon from "../../../icons/GuestIcon";
import ActivationIcon from "../../../icons/ActivationIcon";
import AccountIcon from "../../../icons/AccountIcon";
import { mapState } from "vuex";
export default {
  name: "Wedding",

  components: {
    CollapseIcon,
    PaintIcon,
    GuestIcon,
    ActivationIcon,
    AccountIcon,
  },

  data() {
    return {
      loadingWedding: false,
    };
  },

  computed: {
    ...mapState({
      wedding: "wedding",
      selectedTab: "selectedTab",
      collapsed: "collapsed",
      activateInvitation: "activateInvitation",
    }),
  },

  created() {
    this.loadWedding();
  },

  methods: {
    async loadWedding() {
      this.loadingWedding = true;

      try {
        const response = await api.weddings.getById(this.$route.params.id);
        this.$store.commit("setWedding", response);
        if (!this.wedding.active) {
          this.$store.commit("setActivateInvitation", true);
        }
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingWedding = false;
    },
  },
};
</script>

