<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <g data-name="Layer 2">
      <path fill="none" d="M0 0h48v48H0z" data-name="invisible box" />
      <path
        d="M32.6 22.6a1.9 1.9 0 0 0 0 2.8l5.9 6a2.1 2.1 0 0 0 2.7.2 1.9 1.9 0 0 0 .2-3L38.8 26H44a2 2 0 0 0 0-4h-5.2l2.6-2.6a1.9 1.9 0 0 0-.2-3 2.1 2.1 0 0 0-2.7.2Zm-17.2 2.8a1.9 1.9 0 0 0 0-2.8l-5.9-6a2.1 2.1 0 0 0-2.7-.2 1.9 1.9 0 0 0-.2 3L9.2 22H4a2 2 0 0 0 0 4h5.2l-2.6 2.6a1.9 1.9 0 0 0 .2 3 2.1 2.1 0 0 0 2.7-.2ZM26 6v36a2 2 0 0 0 4 0V6a2 2 0 0 0-4 0Zm-4 36V6a2 2 0 0 0-4 0v36a2 2 0 0 0 4 0Z"
        data-name="icons Q2"
      />
    </g>
  </svg>
</template>