import store from "../store";
import axios from "axios";
import auth from "./authRequests";
import clients from "./clientRequests";
import weddings from "./weddingRequests";
import images from "./imageRequests";
import songs from "./songRequests";
import invitations from "./invitationRequests";
import parents from "./parentRequests";
import events from "./eventRequests";
import giftRegistries from "./giftRegistryRequests";
import accommodations from "./accommodationRequests";
import guests from "./guestRequests";
import invitationPlans from "./invitationPlanRequests";
import payments from "./paymentRequests";

const apiUrl = process.env.VUE_APP_API_URL;
const apiVersion = process.env.VUE_APP_API_VERSION;

const apiClient = axios.create({
  baseURL: `${apiUrl}/${apiVersion}`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiClient.interceptors.response.use(response => {
  return response;
}, (error) => {
  if (error.response.status === 401) {
    store.commit("setShowLoginModal", true);
  } else if (error.response.status === 403) {
    store.dispatch("logout");
  }

  return Promise.reject(error);
});

auth.setClient(apiClient);
clients.setClient(apiClient);
weddings.setClient(apiClient);
images.setClient(apiClient);
songs.setClient(apiClient);
invitations.setClient(apiClient);
parents.setClient(apiClient);
events.setClient(apiClient);
giftRegistries.setClient(apiClient);
accommodations.setClient(apiClient);
guests.setClient(apiClient);
invitationPlans.setClient(apiClient);
payments.setClient(apiClient);

export default {
  auth,
  clients,
  weddings,
  images,
  songs,
  invitations,
  parents,
  events,
  giftRegistries,
  accommodations,
  guests,
  invitationPlans,
  payments
};
