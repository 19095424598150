export default {
  path: '/payments',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async checkout(form) {
    try {
      const response = await this.apiClient.post(`${this.path}/checkout`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}