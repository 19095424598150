<template>
  <button class="flex relative w-12 h-6 items-center" :disabled="disabled" @click.prevent="toggle">
    <div
      class="w-full h-3 rounded-full transition-colors duration-500 ease-in-out"
      :class="[{ 'bg-primary-500': modelValue && !disabled }, { 'bg-gray-300': modelValue && disabled }, { 'bg-gray-300': !modelValue }]"
    ></div>
    <div
      class="bg-white border border-gray-300 rounded-full absolute top-0 bottom-0 w-6 transform transition-transform duration-200 ease-in-out"
      :class="[{ 'translate-x-full': modelValue }, { 'translate-x-0': !modelValue }]"
    ></div>
  </button>
</template>

<script>
export default {
  props: ["modelValue", "disabled"],
  methods: {
    toggle() {
      this.$emit("update:modelValue", !this.modelValue);
      this.$emit("change");
    },
  },
};
</script>