import { createStore } from 'vuex'
import { isLoggedIn, logIn, logOut } from "../utils/auth";
import api from "../api";
import router from "../router";

export default createStore({
  state: {
    client: null,
    wedding: null,
    selectedTab: null,
    guestToEdit: null,
    collapsed: false,
    activateInvitation: false,
    showLoginModal: false,
    snack: null,
  },
  getters: {
  },
  mutations: {
    setClient(state, payload) {
      state.client = payload;
    },

    setWedding(state, payload) {
      state.wedding = payload;
    },

    createClientWedding(state, payload) {
      state.client.weddings.push(payload);
    },

    setSelectedTab(state, payload) {
      state.selectedTab = payload;
    },

    setGuestToEdit(state, payload) {
      state.guestToEdit = payload;
    },

    setCollapsed(state, payload) {
      state.collapsed = payload;
    },

    setActivateInvitation(state, payload) {
      state.activateInvitation = payload;
    },

    setShowLoginModal(state, payload) {
      state.showLoginModal = payload;
    },

    setSnack(state, payload) {
      state.snack = payload;
      setTimeout(() => (state.snack = null), 3000);
    },
  },
  actions: {
    login({ commit }, payload) {
      logIn(payload.token);
      commit("setClient", payload.client);
      commit("setShowLoginModal", false);
    },

    async loadClient({ commit, dispatch }) {
      if (isLoggedIn()) {
        try {
          const client = await api.clients.me();
          commit("setClient", client);
        } catch (error) {
          if (error.response.status !== 401) {
            dispatch("logout");
          }
        }
      }
      return Promise.resolve();
    },

    logout({ commit, state }) {
      commit("setClient", null);
      commit("setWedding", null);
      logOut();
      router.push({ name: 'login' });
    },

    errorSnack({ commit }, payload) {
      commit("setSnack", {
        message: payload,
        type: "error",
      });
    },

    successSnack({ commit }, payload) {
      commit("setSnack", {
        message: payload,
        type: "success",
      });
    },
  },
  modules: {
  }
})
