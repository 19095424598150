import { createRouter, createWebHistory } from 'vue-router'
import { isLoggedIn } from "../utils/auth";
import store from "../store";

// Landing
import Landing from '../views/Landing';

// Auth
import Login from '../views/auth/Login';
import AdminLogin from '../views/auth/AdminLogin';
import GoogleAuth from '../views/auth/GoogleAuth';
import Register from '../views/auth/Register';
import ClientVerification from '../views/auth/ClientVerification';
import Unverified from '../views/auth/Unverified';
import ForgotPassword from '../views/auth/ForgotPassword';
import ResetPassword from '../views/auth/ResetPassword';
import CompleteClient from '../views/auth/CompleteClient';

// Dashboard
import Dashboard from '../views/dashboard/Dashboard';
import WeddingForm from '../views/dashboard/WeddingForm';

// Wedding
import Wedding from '../views/dashboard/wedding/Wedding';
import Editing from '../views/dashboard/wedding/Editing';
import Guests from '../views/dashboard/wedding/Guests';
import Activation from '../views/dashboard/wedding/Activation';
import GuestForm from '../views/dashboard/wedding/GuestForm';
import GuestBatch from '../views/dashboard/wedding/GuestBatch';
import Account from "../views/dashboard/wedding/Account";

// Terms
import Terms from "../views/Terms";

// Not Found
import NotFound from "../views/NotFound";

const routes = [
  {
    path: '/',
    redirect: { name: 'landing' }
  },
  {
    path: '/inicio',
    name: 'landing',
    component: Landing
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/auth/google/callback',
    name: 'google_auth',
    component: GoogleAuth
  },
  {
    path: '/registro',
    name: 'register',
    component: Register
  },
  {
    path: '/olvido-password',
    name: 'forgot_password',
    component: ForgotPassword
  },
  {
    path: '/restablecer-password/:token',
    name: 'reset_password',
    component: ResetPassword
  },
  {
    path: '/verificacion/:token',
    name: 'client_verification',
    component: ClientVerification
  },
  {
    path: '/verificacion',
    name: 'unverified',
    component: Unverified,
    meta: { requiresLogin: true },
  },
  {
    path: '/completar-datos',
    name: 'complete_client',
    component: CompleteClient,
    meta: { requiresLogin: true, requiresVerification: true },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { requiresLogin: true, requiresVerification: true, requiresCompleteClient: true },
    component: Dashboard,
    children: [
      {
        path: '/crear-boda',
        name: 'wedding_form',
        component: WeddingForm,
      },
      {
        path: '/boda/:id',
        name: 'wedding',
        component: Wedding,
        meta: { requiresWedding: true },
        redirect: { name: 'activation' },
        children: [
          {
            path: 'activacion',
            name: 'activation',
            component: Activation
          },
          {
            path: 'edicion',
            name: 'editing',
            component: Editing
          },
          {
            path: 'invitados',
            name: 'guests',
            component: Guests
          },
          {
            path: 'agregar-invitado',
            name: 'guest_form',
            component: GuestForm
          },
          {
            path: 'agregar-archivo',
            name: 'guest_batch',
            component: GuestBatch
          },
          {
            path: 'cuenta',
            name: 'account',
            component: Account
          },
        ]
      },
    ]
  },
  {
    path: '/terminos-condiciones-aviso-privacidad',
    component: Terms,
    name: 'terms'
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
    name: 'not_found'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresLogin) && !store.state.client) {
    next({ name: 'login' });
    return;
  }

  if (to.matched.some(record => record.meta.requiresVerification) && !store.state.client.verified) {
    next({ name: 'unverified' });
    return;
  }

  if (to.matched.some(record => record.meta.requiresCompleteClient) && (!store.state.client.name || !store.state.client.lastName)) {
    next({ name: 'complete_client' });
    return;
  }

  if (to.matched.some(record => record.meta.requiresWedding) && (store.state.client.weddings.length < 1)) {
    next({ name: 'wedding_form' });
    return;
  }

  next();

});

export default router
