<template>
  <div class="flex flex-col p-8">
    <div class="flex items-center">
      <router-link
        :to="{ name: 'guests' }"
        class="text-content-500 fill-current button-interactive "
      >
        <back-icon class="w-5"></back-icon>
      </router-link>
      <!-- <h2>{{ isEditing ? "Actualizar" : "Crear" }} Invitado</h2> -->
    </div>

    <form class="auth-container mt-6 mx-auto">
      <p class="mx-auto title">Agrega un Invitado</p>

      <div class="flex flex-col w-full mt-6">
        <custom-label for="name" value="Nombre"></custom-label>
        <custom-input
          class=""
          id="name"
          type="text"
          v-model="form.name"
        ></custom-input>
        <validation-errors :errors="errorFor('name')"></validation-errors>
      </div>

      <div class="flex flex-col w-full mt-2">
        <custom-label for="phone" value="Teléfono"></custom-label>
        <custom-input
          class=""
          id="phone"
          type="text"
          v-model="form.phone"
        ></custom-input>
        <validation-errors :errors="errorFor('phone')"></validation-errors>
      </div>

      <div class="flex flex-col w-full mt-2">
        <custom-label
          for="maxGuestsAllowed"
          value="# de invitados"
        ></custom-label>
        <custom-input
          class=""
          id="maxGuestsAllowed"
          type="number"
          v-model="form.maxGuestsAllowed"
        ></custom-input>
        <validation-errors
          :errors="errorFor('maxGuestsAllowed')"
        ></validation-errors>
      </div>

      <div class="flex flex-col w-full mt-2">
        <custom-label
          for="specialMessage"
          value="Mensaje Especial"
          details="(Únicamente este invitado podrá ver este mensaje en su invitación)"
        ></custom-label>
        <textarea
          class="h-24 "
          id="specialMessage"
          v-model="form.specialMessage"
        ></textarea>
      </div>

      <primary-button
        v-if="!isEditing"
        class="w-full mt-12"
        :loading="loading"
        @buttonClicked="createGuest"
        >Agregar Invitado</primary-button
      >

      <primary-button
        v-else
        class="w-full mt-12"
        :loading="loading"
        @buttonClicked="updateGuest"
        >Actualizar Invitado</primary-button
      >
    </form>
  </div>
</template>

<script>
import api from "../../../api";
import validationErrors from "../../../mixins/validationErrors";
import { is422 } from "../../../utils/response";
import { mapState } from "vuex";
export default {
  name: "Guest Form",

  data() {
    return {
      form: {
        name: null,
        phone: null,
        maxGuestsAllowed: null,
        specialMessage: null
      },
      loading: false,
      isEditing: false,
    };
  },

  mixins: [validationErrors],

  computed: {
    ...mapState({
      wedding: "wedding",
      guestToEdit: "guestToEdit",
    }),
  },

  created() {
    if (!this.validateIfFeatureAvailable("rsvpAdmin")) {
      this.$router.push({ name: "guests" });
    }

    if (this.guestToEdit) {
      this.form = this.guestToEdit;
      this.isEditing = true;
    }
  },

  beforeRouteLeave() {
    this.$store.commit("setGuestToEdit", null);
  },

  methods: {
    async createGuest() {
      this.loading = true;

      this.form["weddingId"] = this.$route.params.id;

      try {
        const response = await api.guests.create(this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$router.push({ name: "guests" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async updateGuest() {
      this.loading = true;

      try {
        const response = await api.guests.update(
          this.guestToEdit.id,
          this.form
        );
        this.$store.dispatch("successSnack", response.message);
        this.$router.push({ name: "guests" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    validateIfFeatureAvailable(feature) {
      if (!this.wedding.invitationPlan) {
        return false;
      }

      return this.wedding.invitationPlan.invitationPlanFeatures.some(
        (invitationPlanFeature) => {
          return invitationPlanFeature.slug
            .toLowerCase()
            .includes(feature.toLowerCase());
        }
      );
    },
  },
};
</script>