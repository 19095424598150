import ConfirmationModal from "../components/ui/ConfirmationModal";
export default {
  components: {
    ConfirmationModal
  },

  data() {
    return {
      showConfirmationModal: false,
    }
  },

  methods: {
    openConfirmationModal() {
      this.showConfirmationModal = true;
    },

    closeConfirmationModal() {
      this.showConfirmationModal = false;
    }
  }
}