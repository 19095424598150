<template>
  <div class="flex flex-col justify-center items-center absolute inset-0">
    <form class="auth-container relative">
      <div class="w-32 mx-auto">
        <daisi-icon class="w-full" />
      </div>

      <div class="title mx-auto mt-6">Accede a tu cuenta</div>

      <div class="flex flex-col w-full mt-6">
        <custom-label for="email" value="Correo electrónico"></custom-label>
        <custom-input
          id="email"
          type="email"
          v-model="form.email"
        ></custom-input>
        <validation-errors :errors="errorFor('email')"></validation-errors>
      </div>

      <div class="flex flex-col w-full mt-4">
        <div class="flex justify-between">
          <custom-label for="password" value="Contraseña"></custom-label>
          <router-link :to="{ name: 'forgot_password' }" class="link"
            >¿Olvidaste tu contraseña?</router-link
          >
        </div>

        <custom-input
          id="password"
          type="password"
          v-model="form.password"
        ></custom-input>
        <validation-errors :errors="errorFor('password')"></validation-errors>
      </div>

      <primary-button
        class="w-full mt-4"
        :loading="loading"
        @buttonClicked="login"
        >Iniciar Sesión
      </primary-button>

      <google-button
        class="w-full mt-4"
        :loading="loading"
        @buttonClicked="googleSignIn"
      >
        Acceder con Google
      </google-button>

      <div class="mt-6 text-center">
        ¿No tienes cuenta?
        <router-link :to="{ name: 'register' }" class="link"
          >Regístrate</router-link
        >
      </div>
    </form>
  </div>
</template>

<script>
import api from "../../api";
import GoogleButton from "../../components/ui/GoogleButton";
import validationErrors from "../../mixins/validationErrors";
import { is422 } from "../../utils/response";
import { mapState } from "vuex";
import { useHead } from "@unhead/vue";
export default {
  name: "Login",

  setup() {
    useHead({
      titleTemplate: "%s | Login",
    });
  },

  components: {
    GoogleButton,
  },

  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      loading: false,
    };
  },

  mixins: [validationErrors],

  computed: {
    ...mapState({
      client: "client",
    }),
  },

  created() {
    if (this.client) {
      if (this.client.weddings.length > 0) {
        this.$router.push({
          name: "wedding",
          params: { id: this.client.weddings[0].id },
        });
      } else {
        this.$router.push({ name: "wedding_form" });
      }
    }
  },

  methods: {
    validateForm() {
      this.errors = {};

      this.validateEmail(this.form.email);
      this.validateLoginPassword(this.form.password);

      return Object.keys(this.errors).length === 0;
    },

    async login() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      try {
        const response = await api.auth.login(this.form);
        this.$store.dispatch("login", response);
        if (response.client.weddings.length > 0) {
          this.$router.push({
            name: "wedding",
            params: { id: response.client.weddings[0].id },
          });
        } else {
          this.$router.push({ name: "wedding_form" });
        }
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
    },

    async googleSignIn() {
      try {
        const response = await api.auth.getGoogleAuthRedirect();
        window.location.href = response.url;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },
  },
};
</script>

