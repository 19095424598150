<template>
  <div class="flex flex-col">
    <h3>Padres del Novio</h3>

    <div
      v-for="parent in groomsParents"
      :key="parent.id"
      class="flex flex-col w-full rounded-lg shadow-primary p-4 mb-4"
    >
      <w-c-string
        field="name"
        :value="parent.name"
        title="Nombre completo"
        :id="parent.id"
        model="groomsParents"
        :resource="{ api: 'parents', name: 'updateName' }"
        :deleteEnabled="true"
        :active="active"
        @updateName="
          $emit('updateName', { id: parent.id, name: $event });
          setInitialParents();
        "
        @delete="
          $emit('deleteParent', $event);
          setInitialParents();
        "
      />

      <w-c-flag
        field="showIfPastAway"
        :value="parent.showIfPastAway"
        title="Mostrar si falleció"
        :id="parent.id"
        model="groomsParents"
        :resource="{ api: 'parents', name: 'updateShowIfPastAway' }"
        :active="active"
        @update="
          $emit('updateShowIfPastAway', {
            id: parent.id,
            showIfPastAway: $event,
          })
        "
      />
    </div>

    <secondary-button v-if="!addParent && active" @buttonClicked="addParent = true"
      >Agregar</secondary-button
    >

    <w-c-string
      v-else-if="addParent"
      field="name"
      title="Nombre completo"
      model="parent"
      :resource="{ api: 'parents' }"
      :createEnabled="true"
      :createForm="{
        parentOf: 'groom',
        invitationId: invitation.id,
      }"
      @create="
        $emit('createParent', $event);
        setInitialParents();
      "
      @cancel="addParent = false"
    />
  </div>
</template>

<script>
import WCString from "../db-resources/String";
import WCFlag from "../db-resources/Flag";
export default {
  name: "Grooms Parents",

  components: {
    WCString,
    WCFlag,
  },

  data() {
    return {
      groomsParents: null,
      addParent: false,
    };
  },

  props: {
    invitation: Object,
    active: Boolean,
  },

  created() {
    this.setInitialParents();
  },

  methods: {
    setInitialParents() {
      this.groomsParents = JSON.parse(
        JSON.stringify(this.invitation.groomsParents)
      );
      this.addParent = false;
    },
  },
};
</script>